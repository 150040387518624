import {
  TOKEN_ABI,
  Contract_Address,
  Contract_ABI,
} from "./config";
import {
  readContract,
  writeContract,
  fetchBalance,

} from "@wagmi/core";

import { toast } from "react-hot-toast";

const getAddress = {
  address: Contract_Address,
  abi: Contract_ABI,
};

export async function getIdToAddress(id) {
  try {
    const data = await readContract({
      ...getAddress,
      functionName: "idToAddress",
      args: [id],
    });

    return data;
  } catch (error) {
    console.log(error);
  }
}
export async function getUserData(add) {
  try {
    const data = await readContract({
      ...getAddress,
      functionName: "users",
      args: [add],
    });

    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getUserLevelStatus(address, packageidd, level) {
  console.log(address, packageidd, level,"address, packageidd, level")
  try {
    const data = await readContract({
      ...getAddress,
      functionName: "checkUserLevelIsActive",
      args: [address, packageidd, level],
    });

    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function isUserExist(address) {
  try {
    const data = await readContract({
      ...getAddress,
      functionName: "isUserExists",
      args: [address],
    });

    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getPackageExists(address, planid) {
  try {
    const data = await readContract({
      ...getAddress,
      functionName: "isPackageExists",
      args: [address, planid],
    });

    return data;
  } catch (error) {
    console.log(error);
  }
}


export async function getBalanceUser(address, TOKEN_ADDRESS) {
  try {
    const balance = await fetchBalance({
      address: address,
      token: TOKEN_ADDRESS,
    });
    return balance;
  } catch (err) {
    console.log(err);
  }
}

async function registerUSDT(walletAddress, referalAddress, userId, planid) {
  console.log(walletAddress, referalAddress, userId, "calll")
  const res = await writeContract({
    mode: "recklesslyUnprepared",
    ...getAddress,
    functionName: "registrationUSDT",
    args: [referalAddress, userId, planid],
    overrides: {
      from: walletAddress,
      value: 0,
      // gasPrice: 1100000000,
    },
  });
  return res;
}

export async function registerUsdtToken(
  walletAddress,
  packageStaking,
  referalAddress,
  tokenAddress,
  setLoading,
  setRefresh,
  userId,
  navigate,
  planid
) {
  console.log(

    referalAddress,

  );
  try {
    setLoading(true);
    const getBalance = await getBalanceUser(walletAddress, tokenAddress);

    console.log(getBalance, "getBalance::::");
    const res = await readContract({
      address: tokenAddress,
      abi: TOKEN_ABI,
      functionName: "allowance",
      args: [walletAddress, Contract_Address],
    });

    const allowance = Number(res._hex);
    const allowanceval = Number(Number(packageStaking) * 1e18);

    if (getBalance?.formatted >= allowanceval / 1e18) {
      if (
        Number(allowance) >=
        Number(allowanceval.toLocaleString("fullwide", { useGrouping: false }))
      ) {
        const res = await registerUSDT(walletAddress, referalAddress, userId, planid);

        const stakeToken = res?.wait();
        toast
          .promise(stakeToken, {
            loading: " Transaction is Pending....",
            success: (d) => {
              return "Transaction Successfully.";
            },
            error: (error) => {
              return " Transaction request failed.";
            },
          })
          .then((d) => {
            setLoading(false);
            navigate("/LevelUpgradeA");

            setRefresh(true);
          })
          .catch((e) => {
            setLoading(false);
            console.log("Error::", e);
          });
      } else {
        const res = await writeContract({
          mode: "recklesslyUnprepared",
          address: tokenAddress,
          abi: TOKEN_ABI,
          functionName: "approve",
          args: [
            Contract_Address,
            allowanceval
              .toLocaleString("fullwide", { useGrouping: false })
              ?.toString(),
          ],
          overrides: {
            from: walletAddress,
            value: 0,
            // gasPrice: 1100000000,
          },
        });
        const approve = res?.wait();
        toast
          .promise(approve, {
            loading: "Token Approval is Pending....",
            success: (d) => {
              return "Token Transaction Approved.";
            },
            error: (error) => {
              return error.message ?? " Token Approval request failed.";
            },
          })
          .then(async (d) => {
            const res = await registerUSDT(
              walletAddress,
              referalAddress,
              userId,
              planid
            );
            const stakeToken = res?.wait();
            toast
              .promise(stakeToken, {
                loading: " Transaction is Pending....",
                success: (d) => {
                  return "Transaction Successfully.";
                },
                error: (error) => {
                  return error.message ?? "  Approval request failed.";
                },
              })
              .then((d) => {
                setLoading(false);
                setRefresh(true);
                console.log(d, "m");
                navigate("/LevelUpgradeA");
              })
              .catch((e) => {
                setLoading(false);
                console.log("Error::", e);
              });
          });
        // .catch((error) => setBtnLoader(false));
      }
    } else {
      setLoading(false);
      toast.error("low Balance:");
    }
  } catch (error) {
    setLoading(false);
    console.log(error);
  }
}

export async function registerUsdtTokenPlanB(
  walletAddress,
  packageStaking,
  referalAddress,
  tokenAddress,
  setLoading,
  setRefresh,
  userId,
  navigate,
  planid
) {
 
  try {
    setLoading(true);
    const getBalance = await getBalanceUser(walletAddress, tokenAddress);

    console.log(getBalance, "getBalance::::");
    const res = await readContract({
      address: tokenAddress,
      abi: TOKEN_ABI,
      functionName: "allowance",
      args: [walletAddress, Contract_Address],
    });

    const allowance = Number(res._hex);
    const allowanceval = Number(Number(packageStaking) * 1e18);

    if (getBalance?.formatted >= allowanceval / 1e18) {
      if (
        Number(allowance) >=
        Number(allowanceval.toLocaleString("fullwide", { useGrouping: false }))
      ) {
        const res = await registerUSDT(walletAddress, referalAddress, userId, planid);

        const stakeToken = res?.wait();
        toast
          .promise(stakeToken, {
            loading: " Transaction is Pending....",
            success: (d) => {
              return "Transaction Successfully.";
            },
            error: (error) => {
              return " Transaction request failed.";
            },
          })
          .then((d) => {
            setLoading(false);
            navigate("/LevelUpgradeB");

            setRefresh(true);
          })
          .catch((e) => {
            setLoading(false);
            console.log("Error::", e);
          });
      } else {
        const res = await writeContract({
          mode: "recklesslyUnprepared",
          address: tokenAddress,
          abi: TOKEN_ABI,
          functionName: "approve",
          args: [
            Contract_Address,
            allowanceval
              .toLocaleString("fullwide", { useGrouping: false })
              ?.toString(),
          ],
          overrides: {
            from: walletAddress,
            value: 0,
            // gasPrice: 1100000000,
          },
        });
        const approve = res?.wait();
        toast
          .promise(approve, {
            loading: "Token Approval is Pending....",
            success: (d) => {
              return "Token Transaction Approved.";
            },
            error: (error) => {
              return error.message ?? " Token Approval request failed.";
            },
          })
          .then(async (d) => {
            const res = await registerUSDT(
              walletAddress,
              referalAddress,
              userId,
              planid
            );
            const stakeToken = res?.wait();
            toast
              .promise(stakeToken, {
                loading: " Transaction is Pending....",
                success: (d) => {
                  return "Transaction Successfully.";
                },
                error: (error) => {
                  return error.message ?? "  Approval request failed.";
                },
              })
              .then((d) => {
                setLoading(false);
                setRefresh(true);
                console.log(d, "m");
                navigate("/LevelUpgradeB");

              })
              .catch((e) => {
                setLoading(false);
                console.log("Error::", e);
              });
          });
        // .catch((error) => setBtnLoader(false));
      }
    } else {
      setLoading(false);
      toast.error("low Balance:");
    }
  } catch (error) {
    setLoading(false);
    console.log(error);
  }
}

export async function registerUsdtTokenPlanC(
  walletAddress,
  packageStaking,
  referalAddress,
  tokenAddress,
  setLoading,
  setRefresh,
  userId,
  navigate,
  planid
) {
  console.log(

    referalAddress,

  );
  try {
    setLoading(true);
    const getBalance = await getBalanceUser(walletAddress, tokenAddress);

    console.log(getBalance, "getBalance::::");
    const res = await readContract({
      address: tokenAddress,
      abi: TOKEN_ABI,
      functionName: "allowance",
      args: [walletAddress, Contract_Address],
    });

    const allowance = Number(res._hex);
    const allowanceval = Number(Number(packageStaking) * 1e18);

    if (getBalance?.formatted >= allowanceval / 1e18) {
      if (
        Number(allowance) >=
        Number(allowanceval.toLocaleString("fullwide", { useGrouping: false }))
      ) {
        const res = await registerUSDT(walletAddress, referalAddress, userId, planid);

        const stakeToken = res?.wait();
        toast
          .promise(stakeToken, {
            loading: " Transaction is Pending....",
            success: (d) => {
              return "Transaction Successfully.";
            },
            error: (error) => {
              return " Transaction request failed.";
            },
          })
          .then((d) => {
            setLoading(false);
            navigate("/LevelUpgradeC");

            setRefresh(true);
          })
          .catch((e) => {
            setLoading(false);
            console.log("Error::", e);
          });
      } else {
        const res = await writeContract({
          mode: "recklesslyUnprepared",
          address: tokenAddress,
          abi: TOKEN_ABI,
          functionName: "approve",
          args: [
            Contract_Address,
            allowanceval
              .toLocaleString("fullwide", { useGrouping: false })
              ?.toString(),
          ],
          overrides: {
            from: walletAddress,
            value: 0,
            // gasPrice: 1100000000,
          },
        });
        const approve = res?.wait();
        toast
          .promise(approve, {
            loading: "Token Approval is Pending....",
            success: (d) => {
              return "Token Transaction Approved.";
            },
            error: (error) => {
              return error.message ?? " Token Approval request failed.";
            },
          })
          .then(async (d) => {
            const res = await registerUSDT(
              walletAddress,
              referalAddress,
              userId,
              planid
            );
            const stakeToken = res?.wait();
            toast
              .promise(stakeToken, {
                loading: " Transaction is Pending....",
                success: (d) => {
                  return "Transaction Successfully.";
                },
                error: (error) => {
                  return error.message ?? "  Approval request failed.";
                },
              })
              .then((d) => {
                setLoading(false);
                setRefresh(true);
                console.log(d, "m");
                navigate("/LevelUpgradeC");
              })
              .catch((e) => {
                setLoading(false);
                console.log("Error::", e);
              });
          });
        // .catch((error) => setBtnLoader(false));
      }
    } else {
      setLoading(false);
      toast.error("low Balance:");
    }
  } catch (error) {
    setLoading(false);
    console.log(error);
  }
}

async function upgardeLevel(walletAddress, packageid) {
   console.log(walletAddress, packageid,"walletAddress, packageid:::")
  const res = await writeContract({
    mode: "recklesslyUnprepared",
    ...getAddress,
    functionName: "buyLevel",
    args: [Number(packageid)],
    overrides: {
      from: walletAddress,
      value: 0,
      // gasPrice: 1100000000,
    },
  });
  return res;
}

export async function upgradeLevelMember(
  walletAddress,
  packageStaking,
  tokenAddress,
  setLoading,
  packageid,
  cb,
  navigate
) {
  console.log(packageStaking,"packageStaking")
  try {
   
    setLoading(true);
    const getBalance = await getBalanceUser(walletAddress, tokenAddress);
    const res = await readContract({
      address: tokenAddress,
      abi: TOKEN_ABI,
      functionName: "allowance",
      args: [walletAddress, Contract_Address],
    });

    const allowance = Number(res._hex);
    const allowanceval = Number(Number(packageStaking) * 1e18);

    if (getBalance?.formatted >= allowanceval / 1e18) {
      if (
        Number(allowance) >=
        Number(allowanceval.toLocaleString("fullwide", { useGrouping: false }))
      ) {
        const res = await upgardeLevel(walletAddress,packageid);
        const stakeToken = res?.wait();
        toast
          .promise(stakeToken, {
            loading: " Transaction is Pending....",
            success: (d) => {
              return "Transaction Successfully.";
            },
            error: (error) => {
              return " Transaction request failed.";
            },
          })
          .then((d) => {
            setLoading(false);
            cb()
            navigate('/dashboard')

          })
          .catch((e) => {
            setLoading(false);
            console.log("Error::", e);
          });
      } else {
        const res = await writeContract({
          mode: "recklesslyUnprepared",
          address: tokenAddress,
          abi: TOKEN_ABI,
          functionName: "approve",
          args: [
            Contract_Address,
            allowanceval
              .toLocaleString("fullwide", { useGrouping: false })
              ?.toString(),
          ],
          overrides: {
            from: walletAddress,
            value: 0,

          },
        });
        const approve = res?.wait();
        toast
          .promise(approve, {
            loading: "Token Approval is Pending....",
            success: (d) => {
              return "Token Transaction Approved.";
            },
            error: (error) => {
              return error.message ?? " Token Approval request failed.";
            },
          })
          .then(async (d) => {
            const res = await upgardeLevel(walletAddress,  packageid);

            const stakeToken = res?.wait();
            toast
              .promise(stakeToken, {
                loading: " Transaction is Pending....",
                success: (d) => {
                  return "Transaction Successfully.";
                },
                error: (error) => {
                  return error.message ?? "  Approval request failed.";
                },
              })
              .then((d) => {
                setLoading(false);
                cb()
                navigate('/dashboard')
              })
              .catch((e) => {
                setLoading(false);
                console.log("Error::", e);
              });
          });
        // .catch((error) => setBtnLoader(false));
      }
    } else {
      setLoading(false);
      toast.error("low Balance:");
    }
  } catch (error) {
    setLoading(false);
    console.log(error);
  }
}

export async function upgardeSixLevel(
  walletAddress,
  packageStaking,
  tokenAddress,
  setLoading,
  level,
  navigate,
  packageid,
  cb
) {
  try {
   
    setLoading(true);
    const getBalance = await getBalanceUser(walletAddress, tokenAddress);

    const res = await readContract({
      address: tokenAddress,
      abi: TOKEN_ABI,
      functionName: "allowance",
      args: [walletAddress, Contract_Address],
    });

    const allowance = Number(res._hex);
    const allowanceval = Number(Number(packageStaking) * 1e18);

    if (getBalance?.formatted >= allowanceval / 1e18) {
      if (
        Number(allowance) >=
        Number(allowanceval.toLocaleString("fullwide", { useGrouping: false }))
      ) {
        const res = await upgardeLevel(walletAddress, level, packageid);
        const stakeToken = res?.wait();
        toast
          .promise(stakeToken, {
            loading: " Transaction is Pending....",
            success: (d) => {
              return "Transaction Successfully.";
            },
            error: (error) => {
              return " Transaction request failed.";
            },
          })
          .then((d) => {
            setLoading(false);

            navigate('/dashboard')
            cb()
          })
          .catch((e) => {
            setLoading(false);
            console.log("Error::", e);
          });
      } else {
        const res = await writeContract({
          mode: "recklesslyUnprepared",
          address: tokenAddress,
          abi: TOKEN_ABI,
          functionName: "approve",
          args: [
            Contract_Address,
            allowanceval
              .toLocaleString("fullwide", { useGrouping: false })
              ?.toString(),
          ],
          overrides: {
            from: walletAddress,
            value: 0,
            // gasPrice: 1100000000,
          },
        });
        const approve = res?.wait();
        toast
          .promise(approve, {
            loading: "Token Approval is Pending....",
            success: (d) => {
              return "Token Transaction Approved.";
            },
            error: (error) => {
              return error.message ?? " Token Approval request failed.";
            },
          })
          .then(async (d) => {
            const res = await upgardeLevel(walletAddress, level, packageid);

            const stakeToken = res?.wait();
            toast
              .promise(stakeToken, {
                loading: " Transaction is Pending....",
                success: (d) => {
                  return "Transaction Successfully.";
                },
                error: (error) => {
                  return error.message ?? "  Approval request failed.";
                },
              })
              .then((d) => {
                setLoading(false);

                navigate('/dashboard')
                cb()
                console.log(d, "m");
              })
              .catch((e) => {
                setLoading(false);
                console.log("Error::", e);
              });
          });
        // .catch((error) => setBtnLoader(false));
      }
    } else {
      setLoading(false);
      toast.error("low Balance:");
    }
  } catch (error) {
    setLoading(false);
    console.log(error);
  }
}
