import React, { useEffect, useState, useRef } from "react";
//import {Link} from 'react-router-dom';
import { Tab } from "react-bootstrap";
import Select from "react-select";
import { useSelector } from "react-redux";
import { getLevelHistory, getLevelIncome } from "../../../helpers/api";
import moment from "moment/moment";
import { Pagination } from "@mui/material";
import { Loader } from "../Dashboard/Loader";


const LevelBonus = () => {

  const options2 = [
    { value: "ALL", label: "ALL" },
    { value: "1", label: "Level 1" },
    { value: "2", label: "Level 2" },
    { value: "3", label: "Level 3" },
    { value: "4", label: "Level 4" },
    { value: "5", label: "Level 5" },
    { value: "6", label: "Level 6" },


  ];
  const [selectedOption, setSelectedOption] = useState({
    value: "ALL",
    label: "ALL",
  });
  const { userToken } = useSelector((action) => {
    return action.auth;
  });
  const walletDetails = JSON.parse(localStorage.getItem("walletDetails"));
  const walletAddress = walletDetails?.wallet;
  const [apidata, setApidata] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  function getLevelData(p) {
    getLevelHistory(walletAddress, selectedOption.value, p, userToken?.value).then((res) => {

      console.log(res, "res::cdjjdj")
      setTotal(res?.record_count);
      setApidata(res?.data);
      setLoading(true);
    });
  }
  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };
    return date.toLocaleString('en-GB', options); // This outputs "DD/MM/YYYY, HH:MM:SS"
  };
  
  useEffect(() => {
    getLevelData(page);

  }, [walletAddress, page, selectedOption, userToken]);

  const handleChange = (e, p) => {
    getLevelData(p);
    //console.log(p, page, "page:::");
    setPage(p);
  };

  return (
    <div>
      {!loading ? (
        <Loader />
      ) : (
        <Tab.Container defaultActiveKey="Buy">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body pb-0">
                  <div className="d-flex flex-wrap">
                    <Select
                      options={options2}
                      defaultValue={options2[0]}
                      onChange={setSelectedOption}
                      className="custom-react-select ms-4 me-2"
                    />
                  </div>
                </div>
                <div className="card-body">
                  <Tab.Content>
                    <Tab.Pane eventKey="Buy">
                      <Tab.Container defaultActiveKey="BTH">
                        <div className="d-flex align-items-center justify-content-between">
                          <h4 className="heading text-dark">Level Wise Receive Donation</h4>
                        </div>
                        <Tab.Content>
                          <Tab.Pane eventKey="BTH">
                            <div className="table-responsive dataTablehistory">
                              <div
                                id="bthdata_wrapper"
                                className="dataTables_wrapper no-footer"
                              >
                                <table
                                  id="example"
                                  className="table dataTable shadow-hover display"
                                  style={{ minWidth: "845px" }}
                                >
                                  <thead>
                                    <tr>
                                      <th className="text-dark">Txn Hash</th>

                                      <th className="text-dark">Sender</th>
                                      <th className="text-dark">User Id</th>
                                      <th className="text-dark">Level</th>
                                      <th className="text-dark">Total USDT</th>
                                      {/* <th className="text-dark">Percent(%)</th> */}
                                      <th className="text-dark">Net Received USDT</th>
                                      <th className="text-dark">Type</th>
                                      <th className="text-dark">Date</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {apidata?.length > 0 ? (
                                      apidata.map((e, ind) => {

                                        return (
                                          <tr key={ind}>
                                            <td>
                                              {e?.transactionHash?.slice(0, 6)}...
                                              {e?.transactionHash?.slice(-5)}
                                              <a
                                                target="_blank"
                                                href={`https://testnet.bscscan.com/tx/${e?.transactionHash}`}
                                              >
                                                <i className="fas fa-external-link-alt fs-10 ms-1 text-success"></i>
                                              </a>
                                            </td>

                                            <td>
                                              {e?.sender?.slice(0, 6)}...
                                              {e?.sender?.slice(-5)}
                                            </td>
                                            <td>{e.userId}</td>

                                            <td>{e.level}</td>
                                            <td>{e.amount} </td>


                                            <td>  {e?.income?.toFixed(3)}</td>

                                            <td> {e.income_type}  </td>
                                            <td>
                                             {formatDate(e?.createdAt)}

                                            </td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <tr>
                                        <td colSpan={7}>
                                          <div className="text-center  mt-4 mb-2 fw-bold fs-5 dash-heading">
                                            No Data Found.
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                                <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                                  <div
                                    className="dataTables_paginate paging_simple_numbers mb-0"
                                    id="application-tbl1_paginate"
                                  >
                                    <Pagination
                                      color="primary"
                                      count={Math.ceil(total / 10)}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </div>
            </div>
          </div>
        </Tab.Container>
      )}
    </div>
  );
};

export default LevelBonus;
