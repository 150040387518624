import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { sendMessageApi } from '../../../helpers/api';

const ChatPopup = ({ show, handleClose, selectedUser }) => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  
    const userId = userDetails?.userId;
    const [message, setMessage] = useState('');

    const handleSendMessage = () => {
        if (message.trim()) {

            let fData ={
                senderId:userId,
                receiverId:selectedUser,
                message:message
            }
            sendMessageApi(fData)
                .then((res) => {
                    console.log(res,"res:::")
                    if (res.status) {
                        toast.success('Message sent successfully!');
                        setMessage('');
                        handleClose();
                    } else {
                        toast.error(res.message);
                    }
                })
                .catch((error) => {
                    toast.error('Failed to send message.');
                    console.error(error);
                });
        } else {
            toast.error('Please enter a message.');
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Chat with {selectedUser}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <textarea
                    rows="4"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Type your message here..."
                    className="form-control"
                />
            </Modal.Body>
            <Modal.Footer>
                <button className="default-btn" style={{padding:"6px 16px",background:"#dd2626" ,border:"1px solid #dd2626"}} onClick={handleClose}>
                   Back
                </button>
                <button className="default-btn" style={{padding:"6px 16px"}} onClick={handleSendMessage}>
                    Send Message
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default ChatPopup;
