import React, { useEffect } from "react";
import Header from "../../../../Home/Header";
import Footer from "../../../../Home/Footer";
import file3 from "../../../../json/Trust.json";
import Lottie from "lottie-react";
import { Link } from "react-router-dom";
export const TrustMore = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  return (
    <div>
      <Header />
      <div className="container morediv">
        <section className="row spacer-lg pt-0 align-items-center">
          <div className="col-lg-6 col-md-6 mb-3">
            <Lottie animationData={file3} loop={true} />
          </div>
          <div className="col-lg-6 col-md-6 mb-3">
            <h2 className="chainlist-heading">Transparency/Legality </h2>
            <p className="about-para">
              Anyone from any country in the world can purchase crypto assets
              and transfer them as donations through crypto wallet addresses.
              This means one person can give donations to anyone, and multiple
              people can request and receive donations without any legal issues.
              This is completely legitimate. Accepting all countries, there are
              no restrictions at all. Such transactions cannot be restricted by
              anyone.
            </p>
            <p className="about-para">
              So, this club will operate continuously and without interruptions.
              This platform does not accept any fiat currencies, meaning the
              legal currencies of any country. Personal donations, as well as
              receiving donations from multiple people around the world, are for
              various purposes like financial needs, family expenses, children's
              education, personal education, hospital expenses, building a new
              home, or achieving financial freedom.
            </p>
            <p className="about-para">
              Without any difficulty, you can join this club, give some crypto
              assets to selected club members of your choice, and likewise,
              receive donations from many members of the club. You can stay in
              touch with them on social platforms, share knowledge, hold your
              crypto assets for a long time, and sell them when needed as their
              value increases. This way, you can benefit from the club and plan
              for a happy life. This is a wonderful and beneficial club.
            </p>
            <div>
              {" "}
              <Link to="/" className="btn btn-xxs">
                Home
              </Link>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};
