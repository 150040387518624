import React, { useEffect, useState } from "react";
import { Tab } from "react-bootstrap";
import moment from "moment";

import { checkrewardCriteria, getDashBoardDetails, getRewardData, getRewardTimer, leveWiseCount } from "../../../helpers/api";
import { useSelector } from "react-redux";
import { Loader } from "../Dashboard/Loader";
import CountdownTimer from "../../pages/CountDownTimer";

const RewardStatus = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const { userToken } = useSelector((state) => state.auth);
  const walletDetails = JSON.parse(localStorage.getItem("walletDetails"));
  const walletAddress = walletDetails?.wallet;
  const userId = userDetails?.userId;
  const [apidata, setApidata] = useState('');
  const [timertimeStamp, setTimerTimestamp] = useState("");
  const [userData, setUserData] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTimer = () => {
      let formData = { userId: userId, packageId: userToken.value };
      getRewardTimer(formData)
        .then((res) => {
          setTimerTimestamp(res);
        })
        .catch((err) => {
          console.error("Error fetching timer:", err);
        });
    };

    fetchTimer();
    const formData = {
      packageId: userToken?.value,
      wallet_address: walletAddress,
    };

    getDashBoardDetails(formData).then((res) => {
      setUserData(res);
    });
  }, [userId, walletAddress, userToken]);

  useEffect(() => {

    let fData = {
      user: walletAddress,
      packageId: userToken.value
    }
    checkrewardCriteria(fData).then((res) => {
      setApidata(res?.data?.allleveldata)
      setLoading(false)
    })
  }, [walletAddress, userToken])

  return (
    <div>
      <div className="row">
        <div className="col-xxl-12">
          <div className="card">
            <Tab.Container defaultActiveKey="All">
              <div className="card-header border-0 pb-2 flex-wrap">
                <h4 className="heading me-2">Monthly Reward Status</h4>
                <div className="deal-of-the-day-content mb-3 mt-0 d-flex justify-content-center align-items-center">

                  <CountdownTimer timestamp={timertimeStamp?.date ? timertimeStamp?.date : 0} />

                </div>
              </div>
              {userData?.rewardDistributeTime > 0 || apidata?.eligibe ? (
                <div className="card-body pt-2">
                  <div className="badge-container mb-3">
                    <span className="badge bg-primary mt-2 m-2 w-100">Level 1: STAR DMT Completed🎉</span>
                    <span className="badge bg-primary mt-2 m-2 w-100">Level 2: PLANET DMT Completed🎉</span>
                    {apidata?.[3]?.totaTeam > 63 ? <span className="badge bg-primary mt-2 m-2 w-100">Level 3:  SOLAR DMT Completed  🎉</span> : ''}
                    {apidata?.[4]?.totaTeam > 255 ? <span className="badge bg-primary mt-2 m-2 w-100">Level 4:  GALAXY DMT Completed  🎉</span> : ''}
                    {apidata?.[5]?.totaTeam > 1000 ? <span className="badge bg-primary mt-2 m-2 w-100">Level 5:  SUPERNOVA DMT Completed  🎉</span> : ''}
                    {apidata?.[6]?.totaTeam > 4000 ? <span className="badge bg-primary mt-2 m-2 w-100">Level 6:  UNIVERSE DMT Completed  🎉</span> : ''}


                    <span className="badge bg-danger  mt-2 m-2 w-100">Current Level : <span className="fw-bolder">{apidata?.[3]?.totaTeam > 63 && apidata?.[4]?.totaTeam < 255 ? '4th - GALAXY DMT' : apidata?.[4]?.totaTeam > 255 && apidata?.[3]?.totaTeam > 63 && apidata?.[5]?.totaTeam < 1000 ? 'Level 5:SUPERNOVA DMT' : apidata?.[5]?.totaTeam > 1000 && apidata?.[4]?.totaTeam > 255 && apidata?.[3]?.totaTeam > 63 ? 'Level 6: UNIVERSE DMT' : ''} </span></span>

                  </div>
                  <hr />
                  <div id="history_wrapper" className="table-responsive dataTablehistory">
                    {loading ? (
                      <Loader />
                    ) : (
                      <div className="dataTables_wrapper no-footer">
                        <h6>Congratulations, you are eligible for MONTHLY REWARDS 🎖️</h6>
                        {apidata?.[3]?.totaTeam < 63 ?
                          <div className="card">
                            <div className="card-body">
                              <h5 className="card-title text-center mb-3">Current Month Target</h5>
                              <p className="text-center">Each part requires 3 IDs × 4 parts = 12 IDs</p>

                              <div className="row">
                                {/* Completed Section */}


                                {/* To Be Completed Section */}
                                <div className="col-6">
                                  <h6 className="text-warning">Current Status</h6>
                                  <ul className="list-unstyled">
                                    {apidata?.[3]?.counts?.map((count, index) => (
                                      <div key={index}>
                                        {typeof count === 'object' ? (
                                          Object.entries(count).map(([key, value]) => {
                                            const labels = ["A part:", "B part:", "C part:", "D part:"];
                                            const label = labels[parseInt(key)] || `${parseInt(key) + 1} part:`;

                                            return (
                                              <li key={key}>
                                                <strong>{label}</strong> {value}
                                              </li>
                                            );
                                          })
                                        ) : (
                                          <div>{index + 1}. <span className="fw-bolder">{count}</span></div>
                                        )}
                                      </div>
                                    ))}
                                  </ul>
                                </div>

                                <div className="col-6">
                                  <h6 className="text-success">Target Members</h6>
                                  <ul className="list-unstyled">
                                    <li><strong>A part:</strong> 03</li>
                                    <li><strong>B part:</strong> 03</li>
                                    <li><strong>C part:</strong> 03</li>
                                    <li><strong>D part:</strong> 03</li>
                                  </ul>
                                </div>
                                <hr className="mt-2" />
                                <h5 className="card-title text-center mb-3">Month Status </h5>
                                <h6 className="mt-2 badge">STATUS  :  {userData?.rewardMonthStatus ? "🟢 Completed" : "🟠 Inprogress"}</h6>
                                <hr className="mt-2" />
                                <h5 className="card-title text-center mb-3">Level 3th PartWise Team </h5>
                                <div className="col-6">
                                  <h6 className="text-warning">Current Member</h6>
                                  <ul className="list-unstyled">
                                    <li><strong>A part: </strong> {apidata?.[3]?.partWiseCount?.counts?.part1}</li>
                                    <li><strong>B part: </strong> {apidata?.[3]?.partWiseCount?.counts?.part2}</li>
                                    <li><strong>C part: </strong> {apidata?.[3]?.partWiseCount?.counts?.part3}</li>
                                    <li><strong>D part: </strong> {apidata?.[3]?.partWiseCount?.counts?.part4}</li>




                                  </ul>
                                </div>

                                <div className="col-6">
                                  <h6 className="text-success">Total Members</h6>
                                  <ul className="list-unstyled">
                                    <li><strong>A part:</strong> 16</li>
                                    <li><strong>B part:</strong> 16</li>
                                    <li><strong>C part:</strong> 16</li>
                                    <li><strong>D part:</strong> 16</li>
                                  </ul>
                                </div>
                                <hr className="mt-2" />
                                <h5 className="card-title text-center mb-3">Level 3th Total Team </h5>

                                <h6 className="mt-1">TOTAL MEMBER  : {64}</h6>
                                <h6 className="mt-1">AVAILABLE MEMBER  : {64 - apidata?.[3]?.totaTeam}</h6>
                                <h6 className="mt-1">FILL MEMBER : {apidata?.[3]?.totaTeam}</h6>
                               
                              
                              </div>
                            </div>
                          </div>
                          : ''}

                        {apidata?.[4]?.totaTeam < 255 && apidata?.[3]?.totaTeam > 53 ?
                          <div className="card">
                            <div className="card-body">
                              <h5 className="card-title text-center mb-3">Current Month Target</h5>
                              <p className="text-center">Each part requires 8 IDs × 4 parts = 32 IDs</p>

                              <div className="row">
                                {/* Completed Section */}


                                {/* To Be Completed Section */}
                                <div className="col-6">
                                  <h6 className="text-warning">Current Status</h6>
                                  <ul className="list-unstyled">
                                    {apidata?.[4]?.counts?.map((count, index) => (
                                      <div key={index}>
                                        {typeof count === 'object' ? (
                                          Object.entries(count).map(([key, value]) => {
                                            const labels = ["A part:", "B part:", "C part:", "D part:"];
                                            const label = labels[parseInt(key)] || `${parseInt(key) + 1} part:`;

                                            return (
                                              <li key={key}>
                                                <strong>{label}</strong> {value}
                                              </li>
                                            );
                                          })
                                        ) : (
                                          <div>{index + 1}. <span className="fw-bolder">{count}</span></div>
                                        )}
                                      </div>
                                    ))}





                                  </ul>
                                </div>

                                <div className="col-6">
                                  <h6 className="text-success"> Target Members </h6>
                                  <ul className="list-unstyled">
                                    <li><strong>A part:</strong> 08</li>
                                    <li><strong>B part:</strong> 08</li>
                                    <li><strong>C part:</strong> 08</li>
                                    <li><strong>D part:</strong> 08</li>
                                  </ul>
                                </div>
                                <hr className="mt-2" />
                                
                                <h5 className="card-title text-center mb-3">Month Status</h5>

                                <h6 className="mt-2">STATUS  : {userData?.rewardMonthStatus ? "🟢 Completed" : "🟠 Inprogress"}</h6>
                                <hr className="mt-2" />
                                <h5 className="card-title text-center mb-3">Level 4th PartWise Team</h5>
                                <div className="col-6">
                                  <h6 className="text-warning">Current Member</h6>
                                  <ul className="list-unstyled">
                                    <li><strong>A part: </strong> {apidata?.[4]?.partWiseCount?.counts?.part1}</li>
                                    <li><strong>B part: </strong> {apidata?.[4]?.partWiseCount?.counts?.part2}</li>
                                    <li><strong>C part: </strong> {apidata?.[4]?.partWiseCount?.counts?.part3}</li>
                                    <li><strong>D part: </strong> {apidata?.[4]?.partWiseCount?.counts?.part4}</li>




                                  </ul>
                                </div>


                                <div className="col-6">
                                  <h6 className="text-success">Total Members</h6>
                                  <ul className="list-unstyled">
                                    <li><strong>A part:</strong> 64</li>
                                    <li><strong>B part:</strong> 64</li>
                                    <li><strong>C part:</strong> 64</li>
                                    <li><strong>D part:</strong> 64</li>
                                  </ul>
                                </div>
                                <hr className="mt-2" />
                                <h5 className="card-title text-center mb-3">Level 6th Total Team </h5>
                                <h6 className="mt-1">TOTAL MEMBER  : {256}</h6>
                                <h6 className="mt-1">AVAILABLE MEMBER  : {256 - apidata?.[4]?.totaTeam}</h6>
                                <h6 className="mt-1">FILL MEMBER : {apidata?.[4]?.totaTeam}</h6>
                                

                              </div>
                            </div>
                          </div>
                          : ''}

                        {apidata?.[5]?.totaTeam < 1000 && apidata?.[4]?.totaTeam > 225 && apidata?.[3]?.totaTeam > 63 ?
                          <div className="card">
                            <div className="card-body">
                              <h5 className="card-title text-center mb-3">Current Month Target</h5>
                              <p className="text-center">Each part requires 25 IDs × 4 parts = 100 IDs</p>

                              <div className="row">
                                {/* Completed Section */}


                                {/* To Be Completed Section */}
                                <div className="col-6">
                                  <h6 className="text-warning">Current Status</h6>
                                  <ul className="list-unstyled">
                                    {apidata?.[5]?.counts?.map((count, index) => (
                                      <div key={index}>
                                        {typeof count === 'object' ? (
                                          Object.entries(count).map(([key, value]) => {
                                            const labels = ["A part:", "B part:", "C part:", "D part:"];
                                            const label = labels[parseInt(key)] || `${parseInt(key) + 1} part:`;

                                            return (
                                              <li key={key}>
                                                <strong>{label}</strong> {value}
                                              </li>
                                            );
                                          })
                                        ) : (
                                          <div>{index + 1}. <span className="fw-bolder">{count}</span></div>
                                        )}
                                      </div>
                                    ))}





                                  </ul>
                                </div>
                                <div className="col-6">
                                  <h6 className="text-success">Target Members</h6>
                                  <ul className="list-unstyled">
                                    <li><strong>A part:</strong> 25</li>
                                    <li><strong>B part:</strong> 25</li>
                                    <li><strong>C part:</strong> 25</li>
                                    <li><strong>D part:</strong> 25</li>
                                  </ul>
                                </div>
                                <hr className="mt-2" />
                                <h5 className="card-title text-center mb-3">Month Status</h5>
                                <h6 className="mt-2">STATUS  : {userData?.rewardMonthStatus ? "🟢 Completed" : "🟠 Inprogress"}</h6>
                                <hr className="mt-2" />
                                <h5 className="card-title text-center mb-3">Level 5th PartWise Team </h5>
                                <div className="col-6">
                                  <h6 className="text-warning">Current Members</h6>
                                  <ul className="list-unstyled">
                                    <li><strong>A part:</strong>{apidata?.[5]?.partWiseCount?.counts?.part1}</li>
                                    <li><strong>B part:</strong> {apidata?.[5]?.partWiseCount?.counts?.part2}</li>
                                    <li><strong>C part:</strong> {apidata?.[5]?.partWiseCount?.counts?.part3}</li>
                                    <li><strong>D part:</strong> {apidata?.[5]?.partWiseCount?.counts?.part4}</li>




                                  </ul>
                                </div>


                                <div className="col-6">
                                  <h6 className="text-success">Total Members</h6>
                                  <ul className="list-unstyled">
                                    <li><strong>A part:</strong> 256</li>
                                    <li><strong>B part:</strong> 256</li>
                                    <li><strong>C part:</strong> 256</li>
                                    <li><strong>D part:</strong> 256</li>
                                  </ul>
                                </div>
                                <hr className="mt-2" />
                                <h5 className="card-title text-center mb-3">Level 5th Total Team </h5>
                                <h6 className="mt-1">TOTAL MEMBER  : {1024}</h6>
                                <h6 className="mt-1">AVAILABLE MEMBER  : {1024 - apidata?.[5]?.totaTeam}</h6>
                                <h6 className="mt-1">FILL MEMBER : {apidata?.[5]?.totaTeam}</h6>
                               


                              </div>
                            </div>
                          </div>
                          : ''}

                        {apidata?.[6]?.totaTeam < 4000 && apidata?.[5]?.totaTeam > 925 && apidata?.[4]?.totaTeam > 255 && apidata?.[3]?.totaTeam > 63 ?
                          <div className="card">
                            <div className="card-body">
                              <h5 className="card-title text-center mb-3">Current Month Target</h5>
                              <p className="text-center">Each part requires 75 IDs × 4 parts = 300 IDs</p>

                              <div className="row">
                                {/* Completed Section */}


                                {/* To Be Completed Section */}
                                <div className="col-6">
                                  <h6 className="text-warning">Current Status</h6>
                                  <ul className="list-unstyled">
                                    {apidata?.[6]?.counts?.map((count, index) => (
                                      <div key={index}>
                                        {typeof count === 'object' ? (
                                          Object.entries(count).map(([key, value]) => {
                                            const labels = ["A part:", "B part:", "C part:", "D part:"];
                                            const label = labels[parseInt(key)] || `${parseInt(key) + 1} part:`;

                                            return (
                                              <li key={key}>
                                                <strong>{label}</strong> {value}
                                              </li>
                                            );
                                          })
                                        ) : (
                                          <div>{index + 1}. <span className="fw-bolder">{count}</span></div>
                                        )}
                                      </div>
                                    ))}

                                  </ul>
                                </div>
                                <div className="col-6">
                                  <h6 className="text-success">Target Members</h6>
                                  <ul className="list-unstyled">
                                    <li><strong>A part:</strong> 75</li>
                                    <li><strong>B part:</strong> 75</li>
                                    <li><strong>C part:</strong> 75</li>
                                    <li><strong>D part:</strong> 75</li>
                                  </ul>
                                </div>
                                <hr  className="mt-2"/>
                                <h5 className="card-title text-center mb-3">Month Status</h5>
                                <h6 className="mt-2">STATUS  : {userData?.rewardMonthStatus ? "🟢 Completed" : "🟠 Inprogress"}</h6>

                                <hr className="mt-2" />
                                <h5 className="card-title text-center mb-3">Level 6th PartWise Team </h5>
                                <div className="col-6">
                                  <h6 className="text-warning">Current Member</h6>
                                  <ul className="list-unstyled">
                                    <li><strong>A part: </strong> {apidata?.[6]?.partWiseCount?.counts?.part1}</li>
                                    <li><strong>B part: </strong> {apidata?.[6]?.partWiseCount?.counts?.part2}</li>
                                    <li><strong>C part: </strong> {apidata?.[6]?.partWiseCount?.counts?.part3}</li>
                                    <li><strong>D part: </strong> {apidata?.[6]?.partWiseCount?.counts?.part4}</li>




                                  </ul>
                                </div>


                                <div className="col-6">
                                  <h6 className="text-success">Total Members</h6>
                                  <ul className="list-unstyled">
                                    <li><strong>A part:</strong> 1024</li>
                                    <li><strong>B part:</strong> 1024</li>
                                    <li><strong>C part:</strong> 1024</li>
                                    <li><strong>D part:</strong> 1024</li>
                                  </ul>
                                </div>
                                <hr className="mt-2" />
                                <h5 className="card-title text-center mb-3">Level 6th Total Team </h5>
                                <h6 className="mt-1">TOTAL MEMBER  : {4096}</h6>
                                <h6 className="mt-1">AVAILABLE MEMBER  : {4096 - apidata?.[6]?.totaTeam}</h6>
                                <h6 className="mt-1">FILL MEMBER : {apidata?.[6]?.totaTeam}</h6>
                              
                             
                              </div>
                            </div>
                          </div>
                          : ''}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="text-center mt-4 mb-2 fw-bold fs-5 dash-heading">
                  No Data Found.
                </div>
              )}
            </Tab.Container>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RewardStatus;
