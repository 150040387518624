import React, { useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import { useSelector } from "react-redux";
import { deleteRange, getLevelTeam, getRangeData, updateExpectedMember } from "../../../helpers/api";
import moment from "moment/moment";
import { Pagination } from "@mui/material";
import { Loader } from "../Dashboard/Loader";
import toast from "react-hot-toast";
import Select from "react-select";
import ChatPopup from "./ChatPopup";


const LevelWise = () => {
    const options2 = [
        { value: "1", label: "Level 1" },
        { value: "2", label: "Level 2" },
        { value: "3", label: "Level 3" },
        { value: "4", label: "Level 4" },
        { value: "5", label: "Level 5" },
        { value: "6", label: "Level 6" },
    ];

    const [selectedOption, setSelectedOption] = useState(options2[0]);
    const { userToken } = useSelector((state) => state.auth);
    const walletDetails = JSON.parse(localStorage.getItem("walletDetails"));
    const walletAddress = walletDetails?.wallet;
    const [apidata, setApidata] = useState([]);
    const [steps, setSteps] = useState([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);

    const [pos, setPos] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]); // New state for selected items
    const [addedRange, setAddedRange] = useState([]); // New state for selected items
    const [childdata, setChildData] = useState([]);

    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const userId = userDetails?.userId;

    const getLevelData = (wallet, page, level, token, t) => {

        console.log(wallet, page, level, token, t, "wallet, page, level, token, t")
        getLevelTeam(wallet, level, page, token).then((res) => {
            setTotal(res?.totalTeam);
            if (t === 0) {
                const parr = res?.res.map((_, i) => getChildPositions(i + 1));
                setPos(parr);
            }
            setApidata(res?.res);
            setLoading(true);
        });
    };

    useEffect(() => {
        getLevelData(walletAddress, page, selectedOption.value, userToken?.value, 0);
    }, [walletAddress, page, selectedOption, userToken]);

    const handleChange = (e, p) => {
        getLevelData(walletAddress, p, selectedOption.value, userToken?.value);
        setPage(p);
    };

    const getChildPositions = (member) => {
        const start = member * 4 + 1;
        return Array.from({ length: 4 }, (_, i) => start + i);
    };

    const handleCheckboxChange = (userId, downline, position, isChecked, packageId, indexToSend) => {

        console.log(userId, downline, position, isChecked, indexToSend, "userId, downline, positionisChecked,")
        const item = { userId, downline, position, packageId, indexToSend };
        if (isChecked) {
            setSelectedItems((prev) => [...prev, item]);
        } else {
            setSelectedItems((prev) => prev.filter((i) => i.userId !== userId || i.position !== position));
        }
    };

    const handleSubmit = () => {
        console.log("Selected Items: ", selectedItems);
        // Add your submit logic here (e.g., send selectedItems to the server)

        const formData = {
            data: selectedItems
        }
        if (selectedItems.length > 0) {
            updateExpectedMember(formData).then((res) => {

                console.log(res, "res::::")
                if (res.status) {
                    setRefresh(!refresh)
                    toast.success(res?.message)
                    setSelectedItems([])
                } else {

                    toast.error(res?.message)
                }
            })
        } else {
            toast.error("Please select member")
        }

    };

    const ramoveRange = (id) => {

        let fData = {
            id: id,

        }

        deleteRange(fData).then((res) => {

            // console.log(res, "res::::")
            if (res.status) {
                setRefresh(!refresh)
                toast.success(res?.message)
            } else {

                toast.error(res?.message)
            }
        })


    };
    const calculateIndexToSend = (position) => {
        const positionNumber = parseInt(position, 10);

        if (positionNumber < 1 || positionNumber > 5096) {
            return null; // Return null if the position is out of bounds
        }

        // Calculate the group based on sets of 4
        const group = Math.ceil(positionNumber / 4);

        // Return the index based on the position within the group (1-4)
        return ((positionNumber - 1) % 4) + 1;
    };

    // console.log(selectedItems, "selectedItems:::;")

    useEffect(() => {
        let fData = {
            userId: userId,
            packageId: userToken.value
        }
        getRangeData(fData).then((res) => {
            // console.log(res, "res::::")
            setAddedRange(res)
        })
    }, [userId, userToken, refresh])

    const fetchAllUserDetails = async () => {
        if (apidata.length > 0) {
            const promises = apidata.map(data =>
                getLevelTeam(data.address, 1, 1, userToken?.value, 0)
            );
            try {
                const details = await Promise.all(promises);

                const far = details?.map(it => it?.res);
                setChildData(far);
            } catch (error) {
                console.error("Error fetching user details:", error);
            }
        }
    };

    useEffect(() => {
        fetchAllUserDetails(); // Fetch user details when apidata changes
    }, [apidata]);
    //   console.log(childdata, 'setchildata');
   
    const [showChatPopup, setShowChatPopup] = useState(false);
    const [selectedChatUser, setSelectedChatUser] = useState(null);

    // ... existing code ...

    const handleChatClick = (userId) => {
        setSelectedChatUser(userId);
        setShowChatPopup(true);
    };
    return (
        <div>
            {!loading ? (
                <Loader />
            ) : (
                <Tab.Container defaultActiveKey="Buy">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body pb-0">
                                    <div className="d-flex flex-wrap">
                                        <button
                                            className="btn userTree"
                                            style={{
                                                border: "1px solid rgb(179 179 179)",
                                                width: "max-content",
                                                background: "#a5814e",
                                                color: "#fff"
                                            }}
                                            onClick={() => {
                                                if (steps.length > 1) {
                                                    const sarr = [...steps];
                                                    const parr = sarr.pop();
                                                    const pos = sarr[sarr.length - 1];
                                                    getLevelData(pos[1], 1, selectedOption.value, userToken?.value);
                                                    const arr = [];
                                                    for (let j = pos[0][0]; j <= pos[0][3]; j++) {
                                                        arr.push(getChildPositions(j));
                                                    }
                                                    setPos(arr);
                                                    setSteps(sarr);
                                                } else {
                                                    setSteps([]);
                                                    getLevelData(walletAddress, 1, selectedOption.value, userToken?.value, 0);
                                                }
                                            }}
                                        >
                                            {"< Go back"}
                                        </button>

                                        <div className="ps-1 pe-1">
                                            <button
                                                className="btn userTree"
                                                style={{
                                                    border: "1px solid rgb(179 179 179)",
                                                    width: "max-content",
                                                    background: "#a5814e",
                                                    color: "#fff"
                                                }}
                                                onClick={() => {
                                                    handleSubmit()
                                                }}
                                            >
                                                Submit Range
                                            </button>
                                        </div>

                                    </div>
                                </div>
                                <div className="card-body">
                                    <Tab.Content>
                                        <Tab.Pane eventKey="Buy">
                                            <Tab.Container defaultActiveKey="BTH">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <h4 className="heading text-dark">Level Report</h4>
                                                    <h4 className="heading text-info">Level {steps?.length + 1}</h4>
                                                </div>
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="BTH">
                                                        <div className="table-responsive dataTablehistory">
                                                            <table className="table dataTable shadow-hover display" style={{ minWidth: "845px" }}>
                                                                <thead>
                                                                    <tr>
                                                                        <th className="text-dark">Position</th>
                                                                        <th className="text-dark">User Id</th>
                                                                        <th className="text-dark">Email</th>
                                                                        <th className="text-dark">Range</th>
                                                                        <th className="text-dark">Select Range</th>

                                                                        <th className="text-dark">Next Level</th>
                                                                        <th className="text-dark">Date</th>
                                                                        <th className="text-dark">Chat</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {apidata.length > 0 ? (
                                                                        apidata.map((data, ind) => {
                                                                            const ranges = childdata[ind];
                                                                            const maxCheckboxIndex = Math.max(0, 4 - data.direct_member);
                                                                            return (
                                                                                <tr key={ind}>
                                                                                    <td>{data.position == 0 ? 1 : data.position}</td>
                                                                                    <td>{data.userId}</td>
                                                                                    <td>{data.email}</td>
                                                                                    <td>
                                                                                        <span className="badge bg-warning " style={{width:"97px"}}>{pos[ind][0]} - {pos[ind][3]}</span>
                                                                                    </td>
                                                                                    <td>
                                                                                            
                                                                                        {new Array(4).fill().map((_, checkboxIndex) => {
                                                                                            const position = pos[ind][0] + checkboxIndex;
                                                                                            //console.log(pos[ind][data.direct_member],checkboxIndex.toString())
                                                                                            // const position = data.direct_member < 4 ? (pos[ind][data.direct_member] + checkboxIndex).toString() : '';
                                                                                            const isdisabled = ranges?.some(it => it?.position == (checkboxIndex + 1));
                                                                                            const isChecked = selectedItems.some(item => item.position === position);

                                                                                            return (
                                                                                                <span className="m-1">

{steps?.length!=5?
                                                                                                <label key={checkboxIndex}>
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        disabled={isdisabled}
                                                                                                        checked={isdisabled ? isdisabled : isChecked}
                                                                                                        onChange={(e) => {
                                                                                                            const checked = e.target.checked;

                                                                                                            // Calculate the index to send based on the current checkbox state
                                                                                                            const indexToSend = calculateIndexToSend(position);

                                                                                                            // Call handleCheckboxChange with the index to send
                                                                                                            handleCheckboxChange(userId, data.userId, position, checked, userToken?.value, indexToSend);
                                                                                                        }}
                                                                                                    />
                                                                                                    <span className="m-1">{position}</span>
                                                                                                </label>
                                                                                                :null}
                                                                                                </span>
                                                                                            );
                                                                                        })}
                                                                                    </td>

                                                                                    <td>

                                                                                        {steps?.length!=5?
                                                                                         <span className="badge bg-success text-dark" onClick={() => {
                                                                                            if (steps.length < 5) {
                                                                                                getLevelData(data?.address, 1, selectedOption.value, userToken?.value);
                                                                                                const arr = [];
                                                                                                for (let j = pos[ind][0]; j <= pos[ind][3]; j++) {
                                                                                                    arr.push(getChildPositions(j));
                                                                                                }
                                                                                                setPos(arr);
                                                                                                setSteps([...steps, [pos[ind], data?.address]]);
                                                                                            }
                                                                                        }}>
                                                                                            Next Range &#8594;
                                                                                        </span>
                                                                                        :null
                                                                                        
                                                                                        }
                                                                                       
                                                                                    </td>
                                                                                    <td>{moment(data.time).fromNow()}</td>
                                                                                    <td>
                                                                                        <div className="">
                                                                                            <button className="default-btn" style={{padding:"4px 16px"}} onClick={() => handleChatClick(data.userId)}>Chat</button>
                                                                                        </div>
                                                                                    </td>

                                                                                </tr>
                                                                            );
                                                                        })
                                                                    ) : (
                                                                        <tr>
                                                                            <td colSpan={7}>
                                                                                <div className="text-center mt-4 mb-2 fw-bold fs-5 dash-heading">No Data Found.</div>
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                            <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                                                                <div className="dataTables_paginate paging_simple_numbers mb-0">
                                                                    <Pagination
                                                                        color="primary"
                                                                        count={Math.ceil(total / 10)}
                                                                        onChange={handleChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Tab.Container>
                                        </Tab.Pane>
                                    </Tab.Content>

                                    <Tab.Content>
                                        <Tab.Pane eventKey="Buy">
                                            <Tab.Container defaultActiveKey="BTH">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <h4 className="heading text-dark">Range Summary</h4>
                                                </div>
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="BTH">
                                                        <div className="table-responsive dataTablehistory">
                                                            <table className="table dataTable shadow-hover display" style={{ minWidth: "845px" }}>
                                                                <thead>
                                                                    <tr>

                                                                        <th className="text-dark">Downline Id</th>
                                                                        <th className="text-dark">Position</th>
                                                                        <th className="text-dark">Status</th>
                                                                        <th className="text-dark">Action</th>
                                                                        <th className="text-dark">Date</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {addedRange?.length > 0 ? (
                                                                        addedRange?.map((data, ind) => {
                                                                            // console.log(data, "data")
                                                                            return (
                                                                                <tr key={ind}>

                                                                                    <td>{data.downline}</td>
                                                                                    <td>{data.position}</td>
                                                                                    <td>{data.status == 1 ? <span className="badge" style={{background:"#55aa56"}}>Completed </span> : <span className="badge bg-warning">Pending</span>}</td>

                                                                                    {(data.status == 0) ? <td><span className="badge bg-danger" style={{ cursor: 'pointer' }} onClick={() => {

                                                                                        ramoveRange(data?._id
                                                                                        )





                                                                                    }}>Remove </span></td> : <td><span className="badge bg-danger" style={{ opacity: 0.4 }}>Remove </span></td>}


                                                                                    <td>{moment(data.createdAt).fromNow()}</td>
                                                                                </tr>
                                                                            );
                                                                        })
                                                                    ) : (
                                                                        <tr>
                                                                            <td colSpan={7}>
                                                                                <div className="text-center mt-4 mb-2 fw-bold fs-5 dash-heading">No Data Found.</div>
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                            {/* <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                                                                <div className="dataTables_paginate paging_simple_numbers mb-0">
                                                                    <Pagination
                                                                        color="primary"
                                                                        count={Math.ceil(total / 10)}
                                                                        onChange={handleChange}
                                                                    />
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Tab.Container>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </div>
                            </div>
                        </div>
                    </div>
                </Tab.Container>

            )}

            <ChatPopup
                show={showChatPopup}
                handleClose={() => setShowChatPopup(false)}
                selectedUser={selectedChatUser}
            />
        </div>


    );
};

export default LevelWise;
