import React from 'react'
import Header from './Header'
import { useState } from 'react';
const plans = {
    280: {
      membershipFee: 30,
      donationAmounts: [100, 10, 20, 30, 40, 80],
      gasFee: 10,
    },
    520: {
      membershipFee: 30,
      donationAmounts: [130, 20, 30, 40, 50, 200],
      gasFee: 20,
    },
    1000: {
      membershipFee: 30,
      donationAmounts: [150, 50, 100, 200, 250, 250],
      gasFee: 20,
    },
  };
export const PlanDetail = () => {
    const [selectedPlan, setSelectedPlan] = useState(520); // Default plan

    const { membershipFee, donationAmounts, gasFee } = plans[selectedPlan];
    const totalDonation = donationAmounts.reduce((acc, curr) => acc + curr, 0);
    const totalInitialCost = membershipFee + totalDonation + gasFee;
    return (
        <>
            <Header />
      <div className="container plan-row">
        <h2 className="mb-4">Detailed Plan and Economics</h2>
        
        <div className="btn-group mb-4" role="group">
          <button className={`btn btn-primary ${selectedPlan === 280 ? 'active' : ''}`} onClick={() => setSelectedPlan(280)}>280 USDT</button>
          <button className={`btn btn-primary ${selectedPlan === 520 ? 'active' : ''}`} onClick={() => setSelectedPlan(520)}>520 USDT</button>
          <button className={`btn btn-primary ${selectedPlan === 1000 ? 'active' : ''}`} onClick={() => setSelectedPlan(1000)}>1000 USDT</button>
        </div>

        <p>To successfully participate in the DMT Club, each person must initially purchase {totalInitialCost} USDT in their wallet. This amount includes the membership fee, donation amounts for each level, and additional USDT to cover gas and network fees for a total of 7 transactions. Here's a breakdown of the costs and benefits:</p>

        <h3 className="my-3">Membership Fee</h3>
        <ul className="list-group mb-4">
          <li className="list-group-item">{membershipFee} USDT to be paid to DMT Club.</li>
        </ul>

        <h3 className="my-3">Donations to Upline</h3>
        <p>You will need to donate to the wallet addresses of the 6 stages above you according to the values specified:</p>

        <table className="table table-bordered table-striped">
          <thead className="thead-dark">
            <tr>
              <th>Level</th>
              <th>Amount (USDT)</th>
              <th>Total Members to Join</th>
              <th>Cumulative Donations (USDT)</th>
            </tr>
          </thead>
          <tbody>
            {donationAmounts.map((amount, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{amount}</td>
                <td>{Math.pow(4, index)}</td>
                <td>{amount * Math.pow(4, index)}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <h3 className="my-4">Total Initial Purchase</h3>
        <ul className="list-group mb-4">
          <li className="list-group-item">Membership Fee: {membershipFee} USDT</li>
          <li className="list-group-item">Donations: {totalDonation} USDT</li>
          <li className="list-group-item">Estimated Gas and Network Fees (for 7 transactions): {gasFee} USDT</li>
          <li className="list-group-item"><strong>Total: {totalInitialCost} USDT</strong></li>
        </ul>
            <h3 className="mb-3">Cycle Generation and Benefits</h3>
            <p>After joining the DMT Club and completing the donation process, each member must recruit 4 new members. This ensures that donations and rewards flow through the system, benefiting all members involved. When new members join through your referral and complete their donation process, you will receive donations not only from your direct recruits but also from their recruits, and so on, up to 6 levels.</p>

            <ul className="list-group mb-4">
                <li className="list-group-item">After completing your donation process, your form will generate 4 new forms for your recruits, placing you in the first level.</li>
                <li className="list-group-item">You will receive donations from 4 members of your recruits, and those new recruits (4 members) will donate to the wallet addresses of the 5 levels above you.</li>
                <li className="list-group-item">As each person recruits 4 members into the DMT Club, the cycle generates as follows: 4, 16, 64, 256, 1024, 4096, creating 6 levels.</li>
            </ul>

            <h3 className="mb-3">Cycle Completion and Long-Term Benefits</h3>
            <p>Upon completing the entire 6-level cycle, you will achieve permanent membership in the DMT Club, unlocking substantial benefits and opportunities:</p>

            <ul className="list-group mb-4">
                <li className="list-group-item"><strong>No Further Targets:</strong> Once you achieve permanent membership, you will no longer have monthly targets or obligations.</li>
                <li className="list-group-item"><strong>Lifelong Benefits:</strong> Receive significant benefits and rewards from DMT Club every month, ensuring continuous growth and profitability without additional recruitment pressures.</li>
                <li className="list-group-item"><strong>Exclusive Access:</strong> Enjoy advanced features, bonus programs, and special events for permanent members.</li>
            </ul>

            <h3 className="mb-4">Example Table for Donations and Earnings</h3>
            <table className="table table-bordered table-striped">
                <thead className="thead-dark">
                    <tr>
                        <th>Level</th>
                        <th>Members in Level</th>
                        <th>Donation Amount (USDT)</th>
                        <th>Total Donations Received (USDT)</th>
                        <th>Cumulative Earnings (USDT)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td>4</td>
                        <td>130</td>
                        <td>520</td>
                        <td>520</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>16</td>
                        <td>20</td>
                        <td>320</td>
                        <td>840</td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>64</td>
                        <td>30</td>
                        <td>1920</td>
                        <td>2760</td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>256</td>
                        <td>40</td>
                        <td>10240</td>
                        <td>13000</td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>1024</td>
                        <td>50</td>
                        <td>51200</td>
                        <td>64200</td>
                    </tr>
                    <tr>
                        <td>6</td>
                        <td>4096</td>
                        <td>200</td>
                        <td>819200</td>
                        <td>883400</td>
                    </tr>
                </tbody>
            </table>

            <p className="text-muted">This clear and structured procedure will be placed on the website for easy understanding and access.</p>
        </div>
        </>
    )
}
