import React, { useState, useEffect } from "react";

function RewardTimer({ timestamp }) {
  const [timeRemaining, setTimeRemaining] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    if (!timestamp) return;

    // Convert timestamp to milliseconds for correct Date comparisons
    const targetTime = timestamp * 1000;
    
    const updateTimer = () => {
      const distance = targetTime - Date.now();

      if (distance <= 0) {
        setTimeRemaining({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        return;
      }

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((distance / (1000 * 60)) % 60);
      const seconds = Math.floor((distance / 1000) % 60);

      setTimeRemaining({ days, hours, minutes, seconds });
    };

    updateTimer(); // Initial call
    const interval = setInterval(updateTimer, 1000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, [timestamp]);

  return (
    <div id="timer" className="flex-wrap d-flex">
      <div id="days" className="align-items-center flex-column d-flex justify-content-center text-center">
        <span>Days</span>
        {timeRemaining.days}
      </div>
      <div id="hours" className="align-items-center flex-column d-flex justify-content-center text-center">
        <span>Hours</span>
        {timeRemaining.hours}
      </div>
      <div id="minutes" className="align-items-center flex-column d-flex justify-content-center text-center">
        <span>Min</span>
        {timeRemaining.minutes}
      </div>
      <div id="seconds" className="align-items-center flex-column d-flex justify-content-center text-center">
        <span>Sec</span>
        {timeRemaining.seconds}
      </div>
    </div>
  );
}

export default RewardTimer;
