import React, { useEffect } from "react";
import Header from "../../../../Home/Header";
import Footer from "../../../../Home/Footer";
import img from "../../../../images/bhash.jpeg";
import { Link } from "react-router-dom";

export const BhashMore = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  return (
    <div>
      <Header />
      <div className="container morediv">
        <section className="row spacer-lg pt-0 align-items-center">
          <div className="card" style={{ background: "#002400" }}>
            <div className="card-body" >
              <div className="d-flex justify-content-center">

                <img src={img} alt="" className="image-bhash" />
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 mb-3 mt-5">



            <h2 className="chainlist-heading">BHASH Token Overview</h2>

            <p className="about-para">
              Our team at DMT Club is dedicated to executing a robust plan that
              will ensure the BHASH Token’s value continues to rise. By
              replacing USDT with BHASH Tokens for all transactions, we aim to
              create a demand-driven market where the token’s value increases in
              tandem with the growth of our platform.
            </p>

            <h3 className="sub-heading">Why BHASH Token Will Stand Out</h3>
            <p className="about-para">
              One of the key advantages of the BHASH Token is its role within the DMT Club ecosystem. Members will need to purchase BHASH Tokens equivalent to their USDT amount for donations, ensuring that there is a steady demand for the token. As the number of members continues to grow, so too will the demand for BHASH Tokens, leading to an organic increase in its value.
            </p>
            <p className="about-para">
              Unlike other tokens where users might hesitate to buy due to fluctuating rates, our system ensures that members will always need to acquire BHASH Tokens, making it a stable yet growth-oriented asset. Whether the token’s rate is high or low, members will purchase BHASH Tokens equivalent to their required USDT amount, providing continuous support to the token’s market.
            </p>

            <h3 className="sub-heading">Holding and Profiting from BHASH Token</h3>
            <p className="about-para">
              Holding onto BHASH Tokens can be significantly more profitable than holding stable coins like USDT. As the token's value appreciates over time, those who hold their tokens for the long term will stand to gain substantial rewards. This creates a win-win situation for all our members – not only do they contribute to the DMT Club’s growth, but they also secure potential profits for themselves.
            </p>
            <p className="about-para">
              As the BHASH Token's popularity and value increase, it will become a highly sought-after asset, with less likelihood of value depreciation. Early adopters who recognize the potential of this token will undoubtedly reap the benefits of their foresight.
            </p>

            <h3 className="sub-heading">The Future of DMT Club with BHASH Token</h3>
            <p className="about-para">
              We are on the brink of launching the BHASH Token and ushering in a new era of cryptocurrency within the DMT Club. This token represents more than just a currency; it’s the foundation of a new crypto revolution. Our tokenomics are designed to reflect the ambitions and aspirations of our community, and we are committed to making the BHASH Token a symbol of success and innovation.
            </p>
            <p className="about-para">
              Stay tuned for more updates as we prepare to launch the BHASH Token. Together, we’ll be at the forefront of this exciting new chapter, creating a brighter and more prosperous future for all DMT Club members.
            </p>

            <h3 className="sub-heading">What Makes BHASH Token Different?</h3>
            <p className="about-para">
              The DMT Club has meticulously planned the development of an entire ecosystem around the BHASH Token. This includes the launch of our BHASH Crypto Wallet, BHASH Swap, and our own advanced BLOCKCHAIN. These products are set to revolutionize our platform by generating significant revenue, reducing transaction costs, and providing numerous other benefits to our members.
            </p>

            <h3 className="sub-heading">Why This Launch Matters</h3>
            <ul className="about-list">
              <li>
                <strong className="fs-3">Increased Demand and Value Growth:</strong> <span className="about-para"> Once the BHASH Token is launched, all transactions within the DMT Club will shift from USDT to BHASH. This change will drive up demand for the BHASH Token, naturally leading to an increase in its value.</span>
              </li>
              <li>
                <strong className="fs-3">Token Utilization Across Platforms:</strong> <span className="about-para">The BHASH Token is not just limited to the DMT Club. It will be utilized across various platforms, ensuring that it remains in high demand and continues to grow in value.</span>
              </li>
              <li>
                <strong className="fs-3">Member Benefits:</strong><span className="about-para"> Every member of the DMT Club will need to purchase BHASH Tokens equivalent to their USDT transactions. This requirement will not only stabilize the token's value but also ensure that members can profit by holding and trading these tokens at favorable rates.</span>
              </li>
              <li>
                <strong className="fs-3">Long-term Profit Potential:</strong> <span className="about-para"> Unlike USDT, which offers limited growth, BHASH Tokens held over time will provide significant returns. This presents an excellent opportunity for our members to secure long-term financial gains.</span>
              </li>
            </ul>

            <h3 className="sub-heading">A New Era in Crypto</h3>
            <p className="about-para">
              With the upcoming launch of the BHASH Token, we are poised to set the stage for a new revolution in the crypto space. Our focus is on creating a token with strong tokenomics that meets your expectations and surpasses them. This is more than just a token; it's the beginning of a new financial future.
            </p>
            <p className="about-para">
              Stay tuned for the official launch, as we prepare to embark on this exciting journey together. The BHASH Token is set to be a game-changer, and we invite you to be a part of this transformative movement.
            </p>

            <div>
              <Link to="/" className="btn btn-small btn-bac">
                Home
              </Link>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};
