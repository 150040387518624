import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import logo from "../../blacklogo.png";
import { LuHome } from "react-icons/lu";
import backTop from "../../images/browser/login_bg1.png";
import backbottom from "../../images/browser/flower.png";

import toast from "react-hot-toast";
import { forgetPassword, loginmail } from "../../helpers/api";

function ForgetScreen() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const [loading, setLoading] = useState(false);


  function handleLogin() {
    if (!email) {
      toast.error("Enter Userid");
      return;
    }
    setLoading(true);
    const formData = { email: email };

    forgetPassword(formData)
      .then((res) => {
       console.log(res,"res:::")
        if (res?.status) {       
          navigate("/confirmPassword",{ state: { email: email },});
          setLoading(false)
          toast.success(res?.message);
        } else {
          toast.error(res?.message);
          setLoading(false)
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("An error occurred");
      });
  }

  return (
    <>
      <div className="page_content gray_bg">
        <div className="container login_page_container">
          <div className="login_header">
            <div>
              <Link to="/">
                <img
                  src={logo}
                  alt="logo"
                  className="mCS_img_loaded"
                  height={50}
                />
              </Link>
            </div>
            <div className="d-flex gap-3">
              <Link to="/">
                <div className="short_icon_outline">
                  <LuHome />
                </div>
              </Link>
            </div>
          </div>

          <main className="page_wrapper">
            <div className="side_img1">
              <img src={backTop} alt="background top" />
            </div>
            <div className="login-card">
              <h2 className="login_title mb-1">Forget Your Password</h2>
              <p className="text_gray">Please Enter register Email</p>
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="input-icon">
                  <label className="text-dark">Email *</label>
                  <input
                    type="text"
                  
                    name="email "
                    className="form-control"
                    placeholder="Your Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
             
              
                <div className="d-grid mb-1 mt-5 text-center">
                  <button
                    type="button"
                    className="default-btn banner-btn"
                    onClick={handleLogin}
                    disabled={loading}
                  >
                    {loading ? (
                      <div className="spinner-border text-light" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      "Forget Now"
                    )}
                  </button>
                </div>
              
             
              </form>
            </div>
            <div className="side_img2">
              <img src={backbottom} alt="background bottom" />
            </div>
          </main>
        </div>
      </div>
    </>
  );
}

export default ForgetScreen;
