import React, { useEffect, useState, useRef } from "react";
//import {Link} from 'react-router-dom';
import { Tab } from "react-bootstrap";
import { useSelector } from "react-redux";
import moment from "moment/moment";
import { myDonation } from "../../helpers/api";
import { Loader } from "../components/Dashboard/Loader";


const DonationList = () => {


    const { userToken } = useSelector((action) => {
        return action.auth;
    });
    const walletDetails = JSON.parse(localStorage.getItem("walletDetails"));
    const walletAddress = walletDetails?.wallet;
    const [apidata, setApidata] = useState([]);

    const [loading, setLoading] = useState(false);

    function getLevelData() {
        let fData = {
            packageId: userToken?.value,
            sender: walletAddress
        }
        myDonation(fData).then((res) => {

            console.log(res, "res::cdjjdj")

            setApidata(res?.data);
            setLoading(true);
        });
    }

    useEffect(() => {
        getLevelData();

    }, [walletAddress, userToken]);



    return (
        <div>
            {!loading ? (
                <Loader />
            ) : (
                <Tab.Container defaultActiveKey="Buy">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">

                                <div className="card-body">
                                    <Tab.Content>
                                        <Tab.Pane eventKey="Buy">
                                            <Tab.Container defaultActiveKey="BTH">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <h4 className="heading text-dark">My Donation List</h4>
                                                </div>
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="BTH">
                                                        <div className="table-responsive dataTablehistory">
                                                            <div
                                                                id="bthdata_wrapper"
                                                                className="dataTables_wrapper no-footer"
                                                            >
                                                                <table
                                                                    id="example"
                                                                    className="table dataTable shadow-hover display"
                                                                    style={{ minWidth: "845px" }}
                                                                >
                                                                    <thead>
                                                                        <tr>

                                                                            <th className="text-dark">User Id</th>
                                                                            <th className="text-dark"> Donated WalletAddress </th>
                                                                            <th className="text-dark">Level Name</th>
                                                                            <th className="text-dark">Level</th>



                                                                            <th className="text-dark">Donated USDT</th>

                                                                            <th className="text-dark">Date</th>
                                                                            <th className="text-dark">Txn Hash</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {apidata?.length > 0 ? (
                                                                            apidata.map((e, ind) => {

                                                                                return (
                                                                                    <tr key={ind}>


                                                                                        <td>{e.userId}</td>
                                                                                        <td>
                                                                                            {e?.receiver?.slice(0, 6)}...
                                                                                            {e?.receiver?.slice(-5)}
                                                                                        </td>
                                                                                        <td> <span className="badge " style={{ background: "#042701",width:"85px" }}>{e.level == 1 ? 'Star DMT' : e.level == 2 ? 'Planet DMT' : e.level == 3 ? 'Solar DMT' : e.level == 4 ? 'Galaxy DMT' : e.level == 5 ? 'Supernova DMT' : e.level == 6 ? 'Universe DMT' : ''} </span></td>

                                                                                        <td>{e.level}</td>


                                                                                        <td>  {e?.income}</td>


                                                                                        <td>
                                                                                            {moment(
                                                                                                (e.createdAt)
                                                                                            ).fromNow()}

                                                                                            /{new Date(e?.createdAt)?.toISOString()?.slice(0, 10)}

                                                                                        </td>
                                                                                        <td>
                                                                                            {e?.transactionHash?.slice(0, 6)}...
                                                                                            {e?.transactionHash?.slice(-5)}
                                                                                            <a
                                                                                                target="_blank"
                                                                                                href={`https://testnet.bscscan.com/tx/${e?.transactionHash}`}
                                                                                            >
                                                                                                <i className="fas fa-external-link-alt fs-10 ms-1 text-success"></i>
                                                                                            </a>
                                                                                        </td>

                                                                                    </tr>
                                                                                );
                                                                            })
                                                                        ) : (
                                                                            <tr>
                                                                                <td colSpan={7}>
                                                                                    <div className="text-center  mt-4 mb-2 fw-bold fs-5 dash-heading">
                                                                                        No Data Found.
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        )}
                                                                    </tbody>
                                                                </table>

                                                            </div>
                                                        </div>
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Tab.Container>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </div>
                            </div>
                        </div>
                    </div>
                </Tab.Container>
            )}
        </div>
    );
};

export default DonationList;
