import Lottie from "lottie-react";
import React from "react";
import file22 from "../json/gifts.json";


import Benefits from "./Benifits";

export const About = () => {
  return (
    <div>
      <div className="about-container ">
        <div class="container text-center">
          {" "}
          <div className="section-title">
            <h2>Why Dmt Club for Enterprise?</h2>
          </div>
          <div className="container">
            <div className="row ">
              <div className="col-md-4 d-flex flex-column align-items-center justify-content-center text-center mt-4">
                <h2 className="about-cat-heading">
                  Gifts Consultancy Services
                </h2>
                <p className="about-cat-parargraph">
                  Our team is dedicated to ensuring a faster process
                </p>
              </div>
              <div className="col-md-4 d-flex align-items-center justify-content-center mt-5">
                <Lottie
                  animationData={file22}
                  loop={true}
                  style={{ height: "250px" }}
                />
              </div>
              <div className="col-md-4 d-flex flex-column align-items-center justify-content-center text-center mt-4">
                <h2 className="about-cat-heading">Interactive Gifts Add-Ons</h2>
                <p className="about-cat-parargraph">
                  Enhance your Gifts platform with new features
                </p>
              </div>
            </div>
            {/* <div className='row align-items-center mt-3'>
                        <div className='col-md-6 order-dmt '>
                            <h2 className='about-cat-heading mt-4'>What exactly is DMT Club ?</h2>
                            <p className='about-cat-parargraph'>DMT Club's P2P Helping Plan fosters a supportive community where members exchange skills and assistance directly. Experience personalized help, boost your knowledge, and build valuable connections.

                                Our platform ensures trust and reliability through verified profiles and secure communication. Join us and enhance your life while helping others.</p>
                        </div>
                        <div className='col-md-6 d-flex justify-content-center order-dmt-image'>
                            <Lottie animationData={file} loop={true} />
                        </div>
                    </div>

                    <div className='row align-items-center'>
                        <div className='col-md-6 d-flex justify-content-center'>
                            <Lottie animationData={file2} loop={true} />
                        </div>
                        <div className='col-md-6'>
                            <h2 className='about-cat-heading mt-4'>Benefits We offer</h2>
                            <p className='about-cat-parargraph'>
                                <ul>
                                    <li>Lending money for investment of your new projects</li>
                                    <li>more Security and control over money from the rest</li>
                                    <li>The project's long-term vision for the blockchain or cryptocurrency.</li>
                                    <li>The mission statement outlining what the project hopes to achieve</li>

                                </ul>
                            </p>
                        </div>
                    </div>

                    <div className='row align-items-center'>
                        <div className='col-md-6 order-dmt'>
                            <h2 className='about-cat-heading mt-4'>Why DMT Club ?</h2>
                            <p className='about-cat-parargraph'>
                                Investing in digital currency with cash is safe, discreet, and accessible, offering decentralized finance benefits while maintaining asset ownership. Prioritize research, use trustworthy platforms, and ensure safety.

                            </p>
                        </div>
                        <div className='col-md-6 d-flex justify-content-center order-dmt-image'>
                            <Lottie animationData={file3} loop={true} />
                        </div>
                    </div> */}
            <Benefits />
          </div>
        </div>
      </div>
    </div>
  );
};
