import React, { useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  getBalanceUser,
  getIdToAddress,

  getPackageExists,



  registerUsdtToken,

  registerUsdtTokenPlanB,
} from "../../helpers/getWeb3";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { Modal as BootstrapModal } from "bootstrap";
import { checkwalletExist, getMailData, getUserDetails, updateplanStatus } from "../../helpers/api";

import logo from "../../blacklogo.png";
import { USDT_Token_ADDRESS } from "../../helpers/config";

import { ConnectButton } from "@rainbow-me/rainbowkit";
import ConnectButtons from "../components/Button/ConnectButton";
import { Model } from "./Model";
import { Loader } from "../components/Dashboard/Loader";

function Register() {
  const { walletAddress, userExist } = useSelector((action) => {
    return action.auth;
  });

  const navigate = useNavigate();
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  const userId = userDetails?.userId;
  const [value, setValue] = useState(30);
  const [mailData, setMailData] = useState("");
  const [refralId, setReferalId] = useState("");
  const [loading, setLoading] = useState(false);
  const [tokenBalance, setTokenBalnce] = useState(0);

  const [walletStatus, setWalletStatus] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const modalRef = useRef(null);
  const bootstrapModal = useRef(null);
  const [loader, setLoader] = useState(true)
  // Initialize Bootstrap Modal
  // Modal Initialization
  useEffect(() => {

    if (modalRef.current && !bootstrapModal.current) {
      bootstrapModal.current = new BootstrapModal(modalRef.current, {
        backdrop: "static",
        keyboard: false,
      });


    }
  }, []);

  // Function to show the modal
  const handleRegisterClick = () => {

    if (refralId && walletAddress) {
      if (bootstrapModal.current) {
        bootstrapModal.current.show();
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Please provide referral ID and connect your wallet.",
      });
    }
  };



  // Function to handle continuation after accepting the disclaimer
  const handleContinue = () => {

    if (bootstrapModal.current) {
      bootstrapModal.current.hide();
    }
    registerApp(); // Proceed with registration
  };

  function registerApp() {
  
    if (!walletAddress) {
      Swal.fire({
        icon: "error",
        title: "Please connect wallet!",
      });
      return;
    }
    // First case: If mailData?.wallet_address exists, match it with walletAddress
    if (mailData?.wallet_address) {
      if (mailData?.wallet_address !== walletAddress) {
        Swal.fire({
          icon: "error",
          title: `Wallet Address Not Match!`,
        });
        return;
      }
    }
    if (walletStatus?.status === true ? walletStatus?.userId === userId:1) {
    getUserDetails(refralId)
      .then((res) => {

        if (res?.userDetails?.userId) {
          getIdToAddress(res?.userDetails?.userId).then((res) => {


            if (!res.includes("0x0000")) {
              getPackageExists(walletAddress, 1).then((res1) => {
                if (res1) {
                  Swal.fire({
                    icon: "error",
                    title: "Wallet already Exist!  ",
                  });
                } else {
                  if (walletAddress) {

                    registerUsdtToken(
                      walletAddress,
                      value,
                      res,
                      USDT_Token_ADDRESS,
                      setLoading,
                      setRefresh,
                      mailData?.userId,
                      navigate,
                      1
                    );


                  } else {
                    Swal.fire({
                      icon: "error",
                      title: "please connect wallet !  ",
                    });
                  }
                }
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Invalid Sponser ID ! ",
              });
            }
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Invalid Sponser ID ! ",
          });
        }
      })
      .catch((e) => {
        Swal.fire({
          icon: "error",
          title: "Wrong Sponser id !  ",
        });
      });
    }else{
      Swal.fire({
        icon: "error",
        title: `Wallet already register!`,
      });

    }
  }

  useEffect(() => {
    getBalanceUser(walletAddress, USDT_Token_ADDRESS).then((res) => {
      const balance = Number(res?.value / 1e18) || 0;
      setTokenBalnce(balance);
    });
  }, [walletAddress, refresh]);

  useEffect(() => {

    let formData = {

      userId: userId,
    };
    getMailData(formData).then((res) => {
      setMailData(res?.plan1);



    });

    let fDatas = {
    
      user: walletAddress,
    };
    checkwalletExist(fDatas).then((res) => {


      setWalletStatus(res)

    });
  }, [refresh, walletAddress, userId]);

  useEffect(() => {

    let formData = {

      userId: mailData?.referrerId,
    };

    if (mailData?.referrerId) {
      getMailData(formData).then((res) => {

        // setReferalId(res?.plan1?.referrerId);

        if (res?.plan1?.all_level_updgrade) {
          setReferalId(res?.plan1?.userId);
          setLoader(false)

        } else {
          setReferalId("DMT123456");
          setLoader(false)

        }

      });
    }


  }, [mailData])

  return (
    <>
      {/* <NavMain /> */}
      {loader ? <Loader /> : <div className="page_content gray_bg">
        <div className="container login_page_container">
          <div className="login_header">
            <div>
              <img src={logo} alt="" className="dash-logo" />
            </div>
            <div className="d-flex gap-3">
              <ConnectButtons />
            </div>
          </div>

          <main className="page_wrapper">
            <div class="login-card">
              <h2 className="login_title mb-1">Register Here</h2>
              <p className="text_gray">
                Please Register in DMT Club to continue
              </p>
              <div className="text-endtext-white  fs-6">
                <>
                  Balance :{" "}
                  <span className="text-info">
                    {tokenBalance?.toFixed(1)}{" USDT"}

                  </span>
                </>
              </div>
              {mailData?.wallet_address ? <div className="text-endtext-white pb-2 fs-6">
                Register Wallet:{" "}
                <span className="text-warning">
                  {mailData?.wallet_address?.slice(0, 10)}...
                  {mailData?.wallet_address?.slice(-10)}{" "}
                </span>
              </div> : null}

              <div className="input-icon">
                <label className="text-dark mt-2">Referral Id</label>
                <input
                  className="form-control"
                  placeholder=""
                  value={refralId}
                  readOnly
                />
              </div>

              <div className="row">
                <h6 className="card-title pt-3 pb-4text-white">
                  Registration fee: <span className="text-warning"> $30 </span>
                </h6>
              </div>
              <div className="d-grid mb-3 mt-2">

                {walletAddress ? (
                  loading ? (
                    <button
                      className="default-btn banner-btn mt-2 disabled"
                      disabled
                    >
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </button>
                  ) : (
                    <button
                      className="default-btn banner-btn"
                      onClick={handleRegisterClick}

                    >
                      Register Here
                    </button>
                  )
                ) : (
                  <ConnectButton.Custom>
                    {({
                      account,
                      chain,
                      openChainModal,
                      openConnectModal,
                      authenticationStatus,
                      mounted,
                    }) => {
                      // Note: If your app doesn't use authentication, you
                      // can remove all 'authenticationStatus' checks
                      const ready =
                        mounted && authenticationStatus !== "loading";
                      const connected =
                        ready &&
                        account &&
                        chain &&
                        (!authenticationStatus ||
                          authenticationStatus === "authenticated");

                      return (
                        <div
                          {...(!ready && {
                            "aria-hidden": true,
                            style: {
                              opacity: 0,
                              pointerEvents: "none",
                              userSelect: "none",
                            },
                          })}
                        >
                          {(() => {
                            if (!connected) {
                              return (
                                <button
                                  onClick={openConnectModal}
                                  type="button"
                                  className=" btn-xxs btn-secondary  rounded_sm w-100"
                                >
                                  Connect Wallet
                                </button>
                              );
                            }

                            if (chain.unsupported) {
                              return (
                                <button
                                  onClick={openChainModal}
                                  type="button"
                                  className="btn btn-xxs btn-danger dz-xs-flex m-r5"
                                >
                                  Wrong network
                                </button>
                              );
                            }
                            return (
                              <div style={{ display: "flex", gap: 12 }}>
                                {window.location.reload()}
                              </div>
                            );
                          })()}
                        </div>
                      );
                    }}
                  </ConnectButton.Custom>
                )}
              </div>
            </div>
          </main>
        </div>
      </div>}
      <Model ref={modalRef} onContinue={handleContinue} />
    </>
  );
}

export default Register;
