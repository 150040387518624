import React from "react";
import CountUp from "react-countup";

export const Locked = () => {
  return (
    <div className="funfacts-area mb-5 ">
      <div className="">
        <div className="container ">
          <div className="row">
            <div className="col-md-6 text-loced-center">
              <div className="locked-text-heading">
                <CountUp
                  start={0}
                  end={2709401164}
                  duration={3}
                  separator=","
                  prefix="$ "
                />
              </div>
              <p className="locked-text-para">Get Help</p>
            </div>
            <div className="col-md-6 text-loced-center">
              <div className="locked-text-heading">
                {" "}
                <CountUp
                  start={0}
                  end={25408398096}
                  duration={3}
                  separator=","
                  prefix="$ "
                />
              </div>

              <p className="locked-text-para">Provide Help</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
