import React, { useState } from "react";
import { forwardRef } from "react";

// Forwarding ref to access the modal DOM element
export const Model = forwardRef(({ onContinue }, ref) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  return (
    <div
      className="modal fade"
      id="exampleModalToggle"
      aria-hidden="true"
      aria-labelledby="exampleModalToggleLabel"
      tabIndex="-1"
      ref={ref}
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalToggleLabel">
              Disclaimer for Joining DMT Club Donation Program
            </h1>

          </div>
          <div className="modal-body">

            <p>1.The DMT Club membership requires a one-time payment of 30 USDT to activate the membership.</p>
            <p>2. <b>Donation Process:</b> After the membership fee is paid, the member will be required to complete donations across six levels form.</p>
            <p>3.Each level has a specific donation requirement, which must be completed in one donations button from Level 1 to Level 6.</p>
            <p>4.Upon joining, donations will be distributed across 6 levels based on the referral, ensuring benefits for both the member and their network.</p>
            <p>5.The form should be completed with accurate wallet addresses, and all transactions must be made through the DMT Club platform.</p>
            <p>6.Upon successful completion of all six levels, the member will receive  DMT Club membership, unlocking exclusive lifelong benefits.</p>
          
            <p> 7.<b>Time Limit:</b> Upon successful payment of the membership fee, a 72-hour (3-day) timer will start, which will be displayed along with the form allocation. All donations must be completed within this 72-hour period, otherwise, the form will close, and again pay to club membership registration fee 30 usdt for new placement with same User id under same reffaral ID.</p>
            <p>8. <b>Responsibility:</b> It is the member’s responsibility to ensure that all donations are completed successfully and within the given time frame. Failure to do so will result in the cancellation of the membership and forfeiture of any progress made.</p>
            <p>9. <b>Acknowledgment and Commitment:</b> By clicking the checkbox on the Terms and Conditions page, you agree to the following: "I, of my own free will and without any external pressure, have thoroughly understood the DMT Club system. I am satisfied with the process and have chosen to give donations voluntarily, while also being committed to receiving donations from my downline team members. I understand that this decision is binding, and I am committed to working for a permanent membership of the DMT Club, supporting my downline members, and adhering to all the terms and conditions mentioned herein."</p>
          </div>
          <div className="modal-footer">
            <div className="pt-1">
              <input
                type="checkbox"
                className=""
                onChange={handleCheckboxChange}
              />
            </div>
            <p>Accept disclaimer & Continue</p>
            <button
              className="btn btn-xxs btn-primary"
              disabled={!isChecked} // Disable until checked
              onClick={onContinue} // Invoke the passed function
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});
