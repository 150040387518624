import React from "react";
import "../../../css/loader.css";
export const Loader = () => {
  return (
    <>
      <div class="body-loader">
        <div class="container-loader">
          <div class="wrapper-loader">
            <div class="loader"></div>
          </div>
        </div>
      </div>
    </>
  );
};