import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './chat.css';
import logo from "../../../dmt1.png"; // Import the static logo
import { chatHistory, chatUsers, markRead, myDonation, sendMessageApi, transactionApi } from '../../../helpers/api';
import { useSelector } from 'react-redux';
import { FaCheck, FaCheckDouble } from 'react-icons/fa';  // Import checkmark icons from react-icons

const Chat = () => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const { userToken } = useSelector((action) => action.auth);
    const userId = userDetails?.userId;
    const [activeChatId, setActiveChatId] = useState(null);
    const [typedMessage, setTypedMessage] = useState('');
    const [users, setUsers] = useState([]);
    const [chatMessages, setChatMessages] = useState([]);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        // Fetch chat users when the component mounts
        const fetchChatUsers = async () => {
            const chatUsersList = await chatUsers({ userId });
            setUsers(chatUsersList);
        };

        fetchChatUsers();
    }, [userId]);

    useEffect(() => {
        // Fetch chat history when the active chat changes
        const fetchChatHistory = async () => {
            if (activeChatId) {
                const history = await chatHistory({ senderId: userId, receiverId: activeChatId });
                setChatMessages(history);


            }
        };

        // Call fetchChatHistory immediately on component mount or activeChatId change
        fetchChatHistory();

        // Set interval to call fetchChatHistory every 30 seconds
        const interval = setInterval(fetchChatHistory, 30000);

        // Clear interval on component unmount or when activeChatId changes
        return () => clearInterval(interval);
    }, [activeChatId, userId, refresh]);


    const sendMessage = async () => {
        if (typedMessage.trim() !== '' && activeChatId) {
            const messageData = { senderId: userId, receiverId: activeChatId, message: typedMessage };

            // Call the API to send the message
            const response = await sendMessageApi(messageData);
            if (response.status) {
                setChatMessages([
                    ...chatMessages,
                    { senderId: userId, message: typedMessage, isRead: false } // Adjusted structure, set isRead as false initially
                ]);
                setTypedMessage(''); // Clear the input after sending
                setRefresh(!refresh)
            }
        }
    };


    const walletDetails = JSON.parse(localStorage.getItem("walletDetails"));
    const walletAddress = walletDetails?.wallet;
    const [apidata, setApidata] = useState([]);

    const [loading, setLoading] = useState(false);

    function getLevelData() {
        let fData = {
            packageId: userToken?.value,
            sender: walletAddress
        }
        myDonation(fData).then((res) => {

            console.log(res, "res::cdjjdj")

            setApidata(res?.data);
            setLoading(true);
        });
    }

    useEffect(() => {
        getLevelData();

    }, [walletAddress, userToken]);





    return (
        <div className="chat-container">
            {/* Sidebar with user IDs */}
            <div className="chat-window">
                {activeChatId ? (
                    <div className="chat-box">
                        <div className="messages">
                            {chatMessages.map((msg, index) => (
                                <div
                                    key={index}
                                    className={`message ${msg.senderId === userId ? 'sent' : 'received'} ${msg.isRead ? 'read' : 'unread'}`}
                                >
                                    <img src={logo} alt="avatar" className="avatar" />
                                    <div className="message-content">
                                        <span className="user-id">{msg.senderId}</span>
                                        <p className='text-white'>{msg.message}</p>

                                    </div>
                                </div>
                            ))
                            }
                        </div>

                        {/* Input box to type message */}
                        <div className="message-input">
                            <input
                                type="text"
                                placeholder="Type a message..."
                                value={typedMessage}
                                onChange={(e) => setTypedMessage(e.target.value)} // Handle typing
                            />
                            <button onClick={sendMessage}>Send</button>
                        </div>
                    </div>
                ) : (
                    <p className='no-chat-found'>Select a user to chat with</p>
                )}
            </div>
            <div className="user-list">
                <h3>Chats User</h3>
                {users?.length > 0 ? users?.map((user) => (
                    <div
                        key={user.receiverId}
                        className={`user-id ${activeChatId === user.receiverId ? 'active' : ''}`}
                        onClick={() => setActiveChatId(user.receiverId)}
                    >
                        <img src={logo} alt="avatar" className="avatar" />
                        {user.receiverId}
                    </div>
                )) :

                    null
                }

                <h6 className='mt-1'>Upline User ID</h6>

                {apidata?.length > 0 ? apidata?.map((user, i) => {
                    return (
                        <div
                            key={user.userId} // Use 'user' here instead of 'res'
                            className={`user-id ${activeChatId === user.userId ? 'active' : ''}`}
                            onClick={() => setActiveChatId(user.userId)}
                        >
                            <img src={logo} alt="avatar" className="avatar" />
                            {user.userId}
                        </div>
                    );
                }) : <p className='no-chat-found'>No User Found</p>}

            </div>

            {/* Chat window */}

        </div>
    );
};

export default Chat;
