import React, { useState, useEffect, useRef } from "react";
import profile01 from "../ssss.gif"; // Profile image
import profile from "../profile.avif"; // Profile image

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactStars from "react-rating-stars-component";
import { getTestonomials } from "../helpers/api";

export const Testonomials = () => {
  const [data, setData] = useState([]);
  const [expanded, setExpanded] = useState({}); // Track which testimonials are expanded
  const sliderRef = useRef(null); // Create a ref for the slider

  // Carousel settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    getTestonomials().then((res) => {
      setData(res?.data || []);
    });

    // Automatic scrolling function
    const interval = setInterval(() => {
      if (sliderRef.current) {
        sliderRef.current.slickNext(); // Move to the next slide
      }
    }, 6000); // Change slide every 3 seconds

    return () => clearInterval(interval); // Clear interval on component unmount
  }, []);

  // Handle toggling the "Read More" functionality
  const toggleReadMore = (id) => {
    setExpanded((prev) => ({
      ...prev,
      [id]: !prev[id], // Toggle between true/false
    }));
  };

  const getFirst15Words = (text) => {
    const words = text.split(" ");
    return words.slice(0, 15).join(" ");
  };

  return (
    <div className="container-fluid text-center text-white">
      <div className="section-title">
        <h2>Testimonials</h2>
        <p>Testimonials from users of the Dream Maker Team Club!</p>
      </div>

      {/* Carousel */}
      <Slider ref={sliderRef} {...settings}>
        {data?.map((testimonial) => (
          <div key={testimonial.id} className="p-3">
            <div className="benefit-card h-100" style={{ minHeight: '44vh' }}>
              <div className="card-header border-0 pb-0">
                <h5 className="text-primary">Best Highlights</h5>
              </div>
              <div className="card-body d-flex flex-column">
                <div className="profile-blog">
                  <img
                    src={profile01}
                    alt="profile"
                    className="img-fluid mb-4 w-100"
                  />
                </div>
              
                <p className="mb-0 text-white flex-grow-1">
                  {expanded[testimonial._id]
                    ? testimonial.description // Show full text if expanded
                    : `${getFirst15Words(testimonial.description)}...`} {/* Show first 15 words if not expanded */}
                </p>
                <h6
                  className="text-warning"
                  onClick={() => toggleReadMore(testimonial._id)}
                >
                  {expanded[testimonial._id] ? "Read Less" : "Read More"}
                </h6>
                <div className="d-flex justify-content-center mb-2 mt-2">
                  <img
                    src={profile}
                    alt="profile icon"
                    className="img-fluid"
                    style={{ width: '30px', height: '30px', borderRadius: '50%', marginRight: '5px' }} // Adjust size for small profile icon
                  />
                  <h4 className="text-white">{testimonial.userId}</h4>
                </div>
                {/* Static 5-Star Rating */}
                <div className="rating mt-1 d-flex justify-content-center">
                  <ReactStars
                    count={5}
                    value={5} // Static value for full 5-star rating
                    size={24}
                    activeColor="#ffd700"
                    edit={false} // Disable editing for static display
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};
