import React from "react";
import file11 from "../json/optimize-risk-and-reward.json";
import Lottie from "lottie-react";

export const RewardHome = () => {
  return (
    <div>
      <section className="services-area pt-100 pb-70">
        <div className="container">
          <div className="section-title">
            <h2>Rewards Plan</h2>
            <p>
              Procedure Of Recruiting New Members, Team Building, Monthly
              Rewards, Lifetime Membership, Communication And Support.
            </p>
          </div>

          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12">
              <div className="text-center">
              <Lottie animationData={file11} loop={true} />
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              <div className="p-1">
                <h3>1. Recruiting New Members:</h3>
                <ol>
                  <li>
                    Start recruiting new members by sharing your unique referral
                    link provided in your dashboard. Use the link to share on
                    social media platforms, in paid promotions, on your own
                    group’s pages, or with friends.
                  </li>
                  <li>
                    Aim to recruit 4 new members directly under you. There is
                    no time limit; the validity is lifelong. However, to qualify
                    for the rewards plan, ensure your downline consists of a
                    total of 20 members (4 direct recruits and 16 second-level
                    recruits) within 15 days.
                  </li>
                </ol>

                <h3>2. Team Building:</h3>
                <ol start={3}>
                  <li>
                    Ensure each of your 4 direct recruits also recruits 4 new
                    members each, totaling 16 second-level recruits.
                  </li>
                  <li>
                    Achieve a total of 20 members (4 direct recruits and 16
                    second-level recruits) within the initial 15 days to
                    qualify for the monthly reward plan.
                  </li>
                </ol>

                <h3>3. Monthly Rewards Criteria:</h3>
                <ol start={5}>
                  <li>
                    Work with your team to meet the monthly recruitment targets
                    at each level:
                    <ul>
                      <li>3rd Level: Recruit 12 new members</li>
                      <li>4th Level: Recruit 32 new members</li>
                      <li>5th Level: Recruit 100 new members</li>
                      <li>6th Level: Recruit 300 new members</li>
                    </ul>
                  </li>
                  <li>
                    Ensure all parts (A, B, C, D) of your downline meet the
                    specific joining targets to qualify for monthly rewards.
                  </li>
                </ol>

                <h3>4. Lifetime Membership:</h3>
                <ol start={7}>
                  <li>
                    Continue building your team and achieving targets to reach
                    the final level (6th Level) with 4096 members.
                  </li>
                  <li>
                    Upon completing all levels, you will be eligible for lifetime
                    membership and ongoing monthly benefits without further
                    requirements.
                  </li>
                </ol>

                <h3>5. Communication and Support:</h3>
                <ol start={9}>
                  <li>
                    Use the DMT Club dashboard to communicate directly with your
                    upline and downline members.
                  </li>
                  <li>
                    Share knowledge, provide mutual support, and work
                    collaboratively to achieve targets and earn rewards.
                  </li>
                </ol>

               
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
