import React, { useState } from "react";
import toast from "react-hot-toast";
import { apiUrl } from "../../helpers/api";

const FeedBack = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const userId = userDetails?.userId;

  const [refresh, setRefresh] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [wordCount, setWordCount] = useState(0); // State for word count

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent form submission

    if (!message.trim()) {
      toast.error("Feedback cannot be empty");
      return;
    }

    // Check if word count exceeds the limit
    if (wordCount > 30) {
      toast.error("Feedback exceeds the maximum limit of 100 words");
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(`${apiUrl}add-description`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ description: message, userId }),
      });

      const result = await response.json();

      if (result.status) {
        toast.success("Feedback added successfully");
        setMessage("");
        setRefresh(true);
        setWordCount(0);
      } else {
        toast.error("Failed to add feedback");
      }
    } catch (error) {
      console.error("Error adding feedback:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Handle text input and word count
  const handleTextChange = (e) => {
    const inputText = e.target.value;
    const words = inputText.trim().split(/\s+/); // Split by any whitespace
    const wordCount = words.length;

    // Set message and word count up to 100 words
    if (wordCount <= 30) {
      setMessage(inputText);
    }
    setWordCount(wordCount); // Update word count regardless
  };

  return (
    <div>
      <div className="row">
        <div className="col-xxl-12">
          <div className="card">
            <>
              <div className="card-header border-0 pb-2 flex-wrap">
                <h4 className="heading me-2">Add Feedback for DMTClub</h4>
              </div>
              <div className="card-body pt-2">
                <form onSubmit={handleSubmit}>
                  <div className="col-md-12">
                    <label htmlFor="messageTextarea" className="form-label">
                      Add Description (max 30 words)
                    </label>
                    <textarea
                      id="messageTextarea"
                      className="form-control"
                      value={message}
                      required
                      onChange={handleTextChange}
                      style={{ height: "150px" }}
                    />
                    <small>{wordCount}/30 words</small>
                  </div>
                  <div className="mt-3">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={loading}
                    >
                      {loading ? "Submitting..." : "Submit"}
                    </button>
                  </div>
                </form>
              </div>
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedBack;
