import React, { useEffect, useState } from "react";
import { Tab } from "react-bootstrap";
import moment from "moment";
import Countdown, { zeroPad } from "react-countdown";
import { getRewardData } from "../../../helpers/api";
import { useSelector } from "react-redux";
import { Loader } from "../Dashboard/Loader";

const RewardIncome = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const { userToken } = useSelector((state) => state.auth);
  const walletDetails = JSON.parse(localStorage.getItem("walletDetails"));
  const walletAddress = walletDetails?.wallet;

  const [apidata, setApidata] = useState([]);
  const [loading, setLoading] = useState(true);
  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    };
    return date.toLocaleString('en-GB', options); // This outputs "DD/MM/YYYY, HH:MM:SS"
};
  useEffect(() => {
    const fData = {
      user: walletAddress,
      packageId: userToken?.value,
    };
    getRewardData(fData).then((res) => {
      console.log(res, "res");
      setApidata(res?.data || []);
      setLoading(false);
    });
  }, [userToken, walletAddress]);

  const Completionist = () => <span className="text-danger">Expired</span>;

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist />;
    } else {
      return (
        <span className="header-label fs-14 grey">
          <span className="rounded" style={{ width: "56px" }}>
            Days {zeroPad(days)}
          </span>
          :{" "}
          <span className="rounded ms-1">
            {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
          </span>
        </span>
      );
    }
  };

  console.log(apidata, "apidata:::");

  return (
    <div>
      <div>
        <div className="row">
          <div className="col-xxl-12">
            <div className="card">
              <Tab.Container defaultActiveKey="All">
                <div className="card-header border-0 pb-2 flex-wrap">
                  <h4 className="heading me-2">Reward & Recognition</h4>
                </div>
                <div className="card-body pt-2">
                  <div
                    id="history_wrapper"
                    className="table-responsive dataTablehistory"
                  >
                    {loading ? (
                      <Loader />
                    ) : (
                      <div className="dataTables_wrapper no-footer">
                        <table
                          id="example"
                          className="table shadow-hover dataTable display"
                          style={{ minWidth: "845px" }}
                        >
                          <thead>
                            <tr>
                              <th>Txn Hash</th>
                              <th>User</th>
                              <th>Level</th>
                              <th>Amount</th>
                              <th>Credit Status</th>
                              <th>Acheive Date</th>
                              {/* <th>Next Target Time</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {apidata.length > 0 ? (
                              apidata.map((e) => (
                                <tr key={e._id}> {/* Use a unique identifier if available */}
                                  <td>
                                    {e?.txHash?.slice(0, 6)}...
                                    {e?.txHash?.slice(-5)}
                                  </td>
                                  <td>
                                    {e?.userAddress?.slice(0, 6)}...
                                    {e?.userAddress?.slice(-5)}
                                  </td>
                                  <td>{e?.level}</td>
                                  <td>{e?.amount} USDT</td>
                                  <td>
                                    {e?.status === 0 ? (
                                      <span className="badge bg-warning text-secondary">
                                        Pending
                                      </span>
                                    ) : (
                                      <span className="badge bg-success">Success</span>
                                    )}
                                  </td>
                                  <td>   {formatDate(e?.createdAt)}</td>
                                 
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={7}>
                                  <div className="text-center mt-4 mb-2 fw-bold fs-5 dash-heading">
                                    No Data Found.
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RewardIncome;
