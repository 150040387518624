import React, { useEffect, useState } from "react";
import logo from "../Asset 2.png";
import Lottie from "lottie-react";
import { Link } from "react-router-dom";

export default function Header() {
  const style = {
    right: "12px",
    left: "auto",
    top:'-8px',
    textAlign: "center",
    textIndent: "0px",
   
    fontSize: "18px",
  };
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const register = userDetails?.register;
  const email = userDetails?.email;
  const paidregsiter = userDetails?.paidregsiter;

  return (
    <>
      <div className="navbar-area">
        <div className="goldie-responsive-nav">
          <div className="container">
            <div class="goldie-responsive-menu mean-container">
              <div class="mean-bar">
                <Link to="/login" class="meanmenu-reveal" style={style}>
                  <h4 className="text-white">Login</h4>
                </Link>
                <nav class="mean-nav">
                  <ul class="navbar-nav" style={{ display: "none" }}>
                    <li class="nav-item">
                      <a href="#" class="nav-link active">
                        Home <i class="bx bx-chevron-down"></i>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div class="logo">
                <Link to="/" className="logo">
                  <img
                    src={logo}
                    alt=""
                    className="img-fluid"
                    // style={{ height: "55px" }}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="goldie-nav">
          <div className="container-fluid">
            <nav className="navbar navbar-expand-md navbar-light">
              <Link to="/" className="logo">
                <img
                  src={logo}
                  alt=""
                  className=" mCS_img_loaded"
                  style={{ height: "55px" }}
                />
              </Link>
              <div className="collapse navbar-collapse mean-menu">
                <ul className="navbar-nav">
                  {/* <li className="nav-item">
                    <a href="#" className="nav-link">
                      Home
                    </a>
                  </li> */}
                  <li className="nav-item">
                    <Link to="/login" className="nav-link active">
                      Login
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                    <a href="#" className="nav-link">
                      Plan
                    </a>
                  </li> */}

                  
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}
