import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import rotate from "../images/CLUB.png";
import banner1 from "../images/browser/12.jpeg";
import banner2 from "../images/browser/13.jpeg";
import banner103 from "../images/browser/103.jpeg";

import banner19 from "../images/browser/19.jpeg";
import banner133 from "../images/browser/133.jpeg";
import banner112 from "../images/browser/112.jpeg";
import banner111 from "../images/browser/111.jpeg";
import banner110 from "../images/browser/110.jpeg";
import banner109 from "../images/browser/109.jpeg";
import banner108 from "../images/browser/108.jpeg";
import banner107 from "../images/browser/107.jpeg";
import banner104 from "../images/browser/104.jpeg";
import banner105 from "../images/browser/105.jpeg";
import banner106 from "../images/browser/106.jpeg";

import banner20 from "../images/browser/20.jpeg";
import { Link } from "react-router-dom";

// Example data
const sliderData = [
  {
    image: banner1,
    subtitle: "DMT Club - Blockchain-Powered Gift Distribution",
    title: "Together, we build a future of abundance.",
    description:
      "Join the DMT Club, where every donation you make creates a cycle of growth and opportunity. Experience the power of community-driven wealth generation.",
    button1: {
      text: "Signup Here",
      icon: "bx bx-shopping-bag",
      link: "register",
    },
  },
  {
    image: banner2,
    subtitle: "DMT Club - Blockchain-Powered Gift Distributions",
    title: "Success begins with the first step",
    description:
      "Start your journey with the DMT Club by contributing to a system designed for success. Your participation helps build a sustainable financial future",
    button1: {
      text: "Signup Here",
      icon: "bx bx-shopping-bag",
      link: "register",
    },
  },
  {
    image: banner103,
    subtitle: "DMT Club - Blockchain-Powered Gift Distributions",
    title: "Dedication today, financial freedom tomorrow.",
    description:
      "With each level you complete, you move closer to financial freedom. The DMT Club rewards your dedication and commitment.",
    button1: {
      text: "Signup Here",
      icon: "bx bx-shopping-bag",
      link: "register",
    },
  },
  {
    image: banner104,
    subtitle: "DMT Club - Blockchain-Powered Gift Distributions",
    title: "Empowerment is the key to collective prosperity.",
    description:
      "Empower yourself by empowering others. The DMT Club's unique structure ensures that as you grow, so does your community.",
    button1: {
      text: "Signup Here",
      icon: "bx bx-shopping-bag",
      link: "register",
    },
  },
  {
    image: banner105,
    subtitle: "DMT Club - Blockchain-Powered Gift Distributions",
    title: "Impact-driven wealth for a better tomorrow.",
    description:
      "The DMT Club is more than just a financial platform; it's a community where your contributions create lasting impact.",
    button1: {
      text: "Signup Here",
      icon: "bx bx-shopping-bag",
      link: "register",
    },
  },
  {
    image: banner106,
    subtitle: "DMT Club - Blockchain-Powered Gift Distributions",
    title: "Fuel the future with your contribution today.",
    description:
      "Your contribution fuels the growth of a decentralized economy, where everyone has a chance to thrive.",
    button1: {
      text: "Signup Here",
      icon: "bx bx-shopping-bag",
      link: "register",
    },
  },
  {
    image: banner107,
    subtitle: "DMT Club - Blockchain-Powered Gift Distributions",
    title: "Legacy is built on today's actions.",
    description:
      "In the DMT Club, your efforts today set the foundation for lifelong financial security. Build your legacy now.",
    button1: {
      text: "Signup Here",
      icon: "bx bx-shopping-bag",
      link: "register",
    },
  },
  {
    image: banner108,
    subtitle: "DMT Club - Blockchain-Powered Gift Distributions",
    title: "Rewards await those who stay the course.",
    description:
      "Every transaction in the DMT Club brings you closer to unlocking greater rewards. Stay committed to your journey.",
    button1: {
      text: "Signup Here",
      icon: "bx bx-shopping-bag",
      link: "register",
    },
  },
  {
    image: banner109,
    subtitle: "DMT Club - Blockchain-Powered Gift Distributions",
    title: "Opportunity lies in every level you ascend.",
    description:
      "Your path to financial success is paved with the opportunities created by each new level in the DMT Club.",
    button1: {
      text: "Signup Here",
      icon: "bx bx-shopping-bag",
      link: "register",
    },
  },
  {
    image: banner110,
    subtitle: "DMT Club - Blockchain-Powered Gift Distributions",
    title: "United, we thrive; together, we prosper.",
    description:
      "The power of the DMT Club lies in its members. Together, we can create an ecosystem of wealth and opportunity.",
    button1: {
      text: "Signup Here",
      icon: "bx bx-shopping-bag",
      link: "register",
    },
  },
  {
    image: banner111,
    subtitle: "DMT Club - Blockchain-Powered Gift Distributions",
    title: "Transparency and growth pave the way to prosperity.",
    description:
      "Secure your financial future by being part of a system that values transparency, growth, and community.",
    button1: {
      text: "Signup Here",
      icon: "bx bx-shopping-bag",
      link: "register",
    },
  },
  {
    image: banner112,
    subtitle: "DMT Club - Blockchain-Powered Gift Distributions",
    title: "Each step you take brightens the path ahead.",
    description:
      "In the DMT Club, every contribution you make is a step toward a brighter, more prosperous future for all.",
    button1: {
      text: "Signup Here",
      icon: "bx bx-shopping-bag",
      link: "register",
    },
    
  },
  {
    image: banner133,
    subtitle: "DMT Club - Blockchain-Powered Gift Distributions",
    title: "Exponential growth starts with a single act of giving.",
    description:
      "The DMT Club turns your efforts into exponential rewards. The more you give, the more you receive.",
    button1: {
      text: "Signup Here",
      icon: "bx bx-shopping-bag",
      link: "register",
    },
    
  },
  
  {
    image: banner19,
    subtitle: "DMT Club - Blockchain-Powered Gift Distributions",
    title: "Milestones today, independence tomorrow.",
    description:
      "In the DMT Club, every level you complete is a milestone in your journey to financial independence.",
    button1: {
      text: "Signup Here",
      icon: "bx bx-shopping-bag",
      link: "register",
    },
    
  },
  {
    image: banner20,
    subtitle: "DMT Club - Blockchain-Powered Gift Distributions",
    title: "Integrity and growth lead to true success.",
    description:
      "Join a community that values growth, integrity, and success. The DMT Club is your gateway to financial excellence.",
    button1: {
      text: "Signup Here",
      icon: "bx bx-shopping-bag",
      link: "register",
    },
    
  },
 
  
  // Add more items as needed
];

export const Banner = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    arrows: true,
  };
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const register = userDetails?.register;
  const email = userDetails?.email;
  const paidregsiter = userDetails?.paidregsiter;
  return (
    <div className="banner-section" style={{ position: "relative" }}>
      <div className="container-fluid">
        <h3 className="sub-title text-center">Decentralized Autonomous Organization</h3>
        <Slider {...settings}>
          {sliderData.map((slide, index) => (
            <div key={index}>
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-12">
                  <div className="hero-content">
                    {/* <span className="sub-title">{slide.subtitle}</span> */}
                    <h1>{slide.title}</h1>
                    <p>{slide.description}</p>
                    <div className="btn-box">
                      To Explore more Register as  <span className="fw-bolder">Guest user </span>{" "}
                      <Link
                        to={
                          register && email && !paidregsiter
                            ? "logindashboard"
                            : register && email && paidregsiter
                            ? "dashboard"
                            : slide.button1.link
                        }
                        className="default-btn"
                      >
                        <i
                          className={`${slide.button1.icon} icon-arrow before`}
                        />
                        <span className="label">{slide.button1.text}</span>
                        <i
                          className={`${slide.button1.icon} icon-arrow after`}
                        />
                      </Link>{" "}
                      Free
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="hero-image align-items-center d-flex justify-content-center text-center">
                    <img
                      src={slide.image}
                      alt={`Banner Image ${index + 1}`}
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    
    </div>
  );
};
