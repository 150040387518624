import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import logo from "../../blacklogo.png";
import { LuHome } from "react-icons/lu";
import backTop from "../../images/browser/login_bg1.png";
import backbottom from "../../images/browser/flower.png";
import OtpInput from "react-otp-input";
import { otpVerification } from "../../helpers/api";
import toast from "react-hot-toast";

function Otp() {
  const { walletAddress, userExist } = useSelector((action) => {
    return action.auth;
  });
  const route = useLocation();
  const { email, userId } = route.state || {}; // Access the email from the route state
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const navigate = useNavigate();

  function OtpVerifiction() {
    if (otp.length !== 6) {
      toast.error("OTP must be 6 digits long.");
      return;
    }

    setLoading(true); // Start loading

    let formData = {
      email: email,
      otp: otp,
      userId: userId,
    };

    otpVerification(formData)
      .then((res) => {
        setLoading(false); // End loading
        if (res?.status) {
          localStorage.setItem(
            "userDetails",
            JSON.stringify({
              email: email,

              userId: userId,
            })
          );
          toast.success(res?.message);
          navigate("/logindashboard");
        } else {
          toast.error(res?.message);
        }
      })
      .catch((err) => {
        setLoading(false); // End loading
        Swal.fire({
          icon: "error",
          title: "Registration failed",
          text: err.message,
        });
      });
  }

  return (
    <div className="page_content gray_bg">
      <div className="container login_page_container">
        <div className="login_header">
          <div>
            <Link to="/">
              <img src={logo} alt=""  className="dash-logo" />
            </Link>
          </div>
          <div className="d-flex gap-3">
            <Link to="/">
              <div className="short_icon_outline">
                <LuHome />
              </div>
            </Link>
          </div>
        </div>

        <main className="page_wrapper">
          <div className="side_img1">
            <img src={backTop} alt="" />
          </div>
          <div className="login-card">
            <h2 className="login_title mb-1">Enter Verification Code</h2>
            <p className="text_gray">please check {email}</p>
            <div>
              <div className="input-icon">
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderSeparator={<span>-</span>}
                  renderInput={(props) => (
                    <input
                      {...props}
                      className="otp-box"
                      
                    />
                  )}
                />
              </div>

              <div className="d-grid mb-1 mt-5 text-center">
                <button
                  className="default-btn banner-btn"
                  onClick={() => OtpVerifiction()}
                  disabled={loading} // Disable the button while loading
                >
                  {loading ? (
                    <div className="spinner-border text-light" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    "Verify OTP"
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className="side_img2">
            <img src={backbottom} alt="" />
          </div>
        </main>
      </div>
    </div>
  );
}

export default Otp;
