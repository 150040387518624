import React from "react";

import file3 from "../../../../images/647.jpg";
export const Reward = () => {
    return (
        <div>

            <div className="container">
                <section className="row spacer-lg pt-0 align-items-center">
                    <div className="">
                        <div className="card-body" style={{ background: "#002400" }}>
                            <div className="ratio ratio-16x9">

                                <img src={file3} alt=""  />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 mb-3">
                        <h2 className="chainlist-heading">DMT Club Permanent Reward Plan</h2>
                        <p className="about-para">
                            The DMT Club Permanent Reward Plan is designed to provide lasting
                            benefits to its members through a structured and collaborative
                            team-building approach. This document details the entire reward
                            system, from initial joining to achieving lifetime membership
                            status.
                        </p>

                        <h3>1. Joining and Initial Targets</h3>
                        <p className="about-para">
                            Upon joining the DMT Club, you have a target window of 15 days to
                            recruit 4 new members directly under you. Each of these 4 members
                            must also recruit 4 new members each, totaling 16 new members.
                            Overall, you need to have a total of 20 members (4 direct recruits
                            and 16 second-level recruits) within 15 days. Achieving this
                            target qualifies you for the monthly reward plan.
                        </p>

                        <h3>2. Monthly Reward Criteria</h3>
                        <p className="about-para">
                            Team Collaboration and Support is required to earn monthly rewards.
                            You and your downline must work together, providing mutual support
                            and sharing knowledge about the DMT Club. The following monthly
                            targets must be met within 30 days:
                            <ul>
                                <li>3rd Level: Recruit 12 new members to the downline tree.</li>
                                <li>4th Level: Recruit 32 new members to the downline tree.</li>
                                <li>5th Level: Recruit 100 new members to the downline tree.</li>
                                <li>6th Level: Recruit 300 new members to the downline tree.</li>
                            </ul>
                            <strong>Reward Distribution:</strong>
                            <ul>
                                <li>3rd Level: 200 USDT</li>
                                <li>4th Level: 500 USDT</li>
                                <li>5th Level: 2000 USDT</li>
                                <li>6th Level: 5000 USDT</li>
                            </ul>
                            These amounts are deposited monthly to your wallet from the DMT
                            Club.
                        </p>

                        <h3>3. Detailed Target Breakdown</h3>
                        <p className="about-para">
                            Each level has specific joining targets divided into 4 parts (A, B, C, D).
                            If any part fails to meet the target, you won't qualify for the reward that month.
                            You must try again the following month. The breakdown for each level:
                        </p>
                        <ul>
                            <li><strong>3rd Level:</strong> 64 members divided into 4 parts of 16 members each.</li>
                            <li><strong>4th Level:</strong> 256 members divided into 4 parts of 64 members each.</li>
                            <li><strong>5th Level:</strong> 1024 members divided into 4 parts of 256 members each.</li>
                            <li><strong>6th Level:</strong> 4096 members divided into 4 parts of 1024 members each.</li>
                        </ul>

                        <h3>4. Lifetime Membership</h3>
                        <p className="about-para">
                            Upon completing all levels and recruiting the required members at
                            each stage, you become a permanent member of the DMT Club. As a
                            permanent member, you will receive a share of 10% of the total
                            USDT collected from new memberships, distributed equally among all
                            permanent members every month.
                        </p>

                        <h3>5. Levels and Titles</h3>
                        <ul>
                            <li><strong>Star DMT:</strong> 1st Level with 4 members</li>
                            <li><strong>Planet DMT:</strong> 2nd Level with 16 members</li>
                            <li><strong>Solar DMT:</strong> 3rd Level with 64 members</li>
                            <li><strong>Galaxy DMT:</strong> 4th Level with 256 members</li>
                            <li><strong>Supernova DMT:</strong> 5th Level with 1024 members</li>
                            <li><strong>Universe DMT:</strong> 6th Level with 4096 members</li>
                        </ul>

                        <p className="about-para">
                            This comprehensive plan aims to provide ongoing rewards and
                            incentives for members to build and maintain their teams. By
                            achieving the targets and supporting each other, members can enjoy
                            substantial monthly rewards and secure a permanent membership with
                            a share of the DMT Club's earnings.
                        </p>
                    </div>
                </section>
            </div>

        </div>
    );
};
