import React from "react";
import { Link } from "react-router-dom";
import file11 from "../images/post4.webp";
import Lottie from "lottie-react";

export const OverView = () => {
  return (
    <div>
      <section className="services-area pt-100 pb-70">
        <div className="container">
          <div className="section-title">
            <h2>Overview</h2>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12">
              <div className=" text-center">
                <img src={file11} alt="" />
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              <div className="about-text">
                <p>
                  - The DMT Club is the world’s first and largest crypto gift
                  donation club where members from all countries can join by
                  donating a small amount of crypto assets.

                </p>
                <p>
                  - Upon joining,members can receive large amounts of crypto donations, hold
                  them long-term, and sell them at a good rate on crypto
                  exchanges, thus significantly improving their financial
                  status.

                </p>
                <p>- No local currency or fiat currency is involved; it’s entirely legal as crypto tokens are considered assets like gold, silver, or real estate.

                </p>

                <a href="overview" className="default-btn">
                  <i className="bx bx-food-menu icon-arrow before" />
                  <span className="label">Read More</span>
                  <i className="bx bx-food-menu icon-arrow after" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
