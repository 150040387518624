import React, { useContext, useReducer, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import Collapse from 'react-bootstrap/Collapse';
import { Link, NavLink } from "react-router-dom";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import { MenuList } from './Menu';
import { useDispatch } from "react-redux";

const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

// Set the first menu item as active by default
const initialState = {
  active: MenuList[0]?.title || "", // Set default active menu
  activeSubmenu: "", // No submenu active by default
};

const SideBar = () => {
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
  } = useContext(ThemeContext);

  const [state, setState] = useReducer(reducer, initialState);
  const dispatch = useDispatch();

  // Scroll hide/show logic
  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );

  const handleMenuActive = (status) => {
    setState({ active: state.active === status ? "" : status });
  };

  const handleSubmenuActive = (status) => {
    setState({ activeSubmenu: state.activeSubmenu === status ? "" : status });
  };

  const path = window.location.pathname.split("/").pop();

  return (
    <div
      className={`deznav border-right ${iconHover} ${sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
        ? hideOnScroll > 120
          ? "fixed"
          : ""
        : ""
        }`}
    >
      <PerfectScrollbar className="deznav-scroll">
        <ul className="metismenu" id="menu">
          {MenuList.map((data, index) => {
            const menuClass = data.classsChange;

            if (menuClass === "menu-title") {
              return (
                <li className={menuClass} key={index}>
                  {data.title}
                </li>
              );
            }

            return (
              <li
                className={`${state.active === data.title ? 'mm-active' : ''}`}
                key={index}
              >
                {data.content && data.content.length > 0 ? (
                  <Link
                    to="#"
                    className="has-arrow"
                    onClick={() => handleMenuActive(data.title)}
                  >
                    {data.iconStyle}
                    <span className="nav-text">{data.title}</span>
                  </Link>
                ) : (
                  <NavLink to={data.to}>
                    {data.iconStyle}
                    <span className="nav-text">{data.title}</span>
                  </NavLink>
                )}
                <Collapse in={state.active === data.title}>
                  <ul className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}>
                    {data.content && data.content.map((item, subIndex) => (
                      <li
                        key={subIndex}
                        className={`${state.activeSubmenu === item.title ? "mm-active" : ""}`}
                      >
                        {item.content && item.content.length > 0 ? (
                          <>
                            <NavLink
                              to={item.to}
                              className={item.hasMenu ? 'has-arrow' : ''}
                              onClick={() => handleSubmenuActive(item.title)}
                            >
                              {item.title}
                            </NavLink>
                            <Collapse in={state.activeSubmenu === item.title}>
                              <ul className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}>
                                {item.content.map((subItem, subIndex) => (
                                  <li key={subIndex}>
                                    <Link
                                      className={`${path === subItem.to ? "mm-active" : ""}`}
                                      to={subItem.to}
                                    >
                                      {subItem.title}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </Collapse>
                          </>
                        ) : (
                          <Link to={item.to}>
                            {item.title}
                          </Link>
                        )}
                      </li>
                    ))}
                  </ul>
                </Collapse>
              </li>
            );
          })}
       
        </ul>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
