import React from "react";
//import { useNavigate } from "react-router-dom";


export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";
export const WALLET_ADDRESS_ACTION = "WALLET_ADDRESS";
export const USER_EXIST_ACTION = "USER_EXIST";
export const USER_ALL_DETAILS_ACTION = "USER_DETAILS";
export const USER_REFRESH_ACTION = 'USER_REFRESH'
export const USER_TOKEN_ACTION = 'USER_TOKEN'
// export function signupAction(email, password, navigate) {
//   return (dispatch) => {
//     signUp(email, password)
//       .then((response) => {
//         saveTokenInLocalStorage(response.data);
//         runLogoutTimer(
//           dispatch,
//           response.data.expiresIn * 1000
//           //history,
//         );
//         dispatch(confirmedSignupAction(response.data));
//         navigate("/dashboard");
//         //history.push('/dashboard');
//       })
//       .catch((error) => {
//         const errorMessage = formatError(error.response.data);
//         dispatch(signupFailedAction(errorMessage));
//       });
//   };
// }

// export function Logout(navigate) {
//   localStorage.removeItem("userDetails");
//   navigate("/login");
//   //history.push('/login');

//   return {
//     type: LOGOUT_ACTION,
//   };
// }

// export function loginAction(email, password, navigate) {
//   return (dispatch) => {
//     login(email, password)
//       .then((response) => {
//         saveTokenInLocalStorage(response.data);
//         runLogoutTimer(dispatch, response.data.expiresIn * 1000, navigate);
//         dispatch(loginConfirmedAction(response.data));
//         //console.log('kk------1');
//         //console.log(kk);
//         //console.log(response.data);
//         //console.log('kk------2');
//         //return response.data;
//         //return 'success';
//         //history.push('/dashboard');
//         navigate("/dashboard");
//       })
//       .catch((error) => {
//         //console.log('error');
//         //console.log(error);
//         const errorMessage = formatError(error.response.data);
//         dispatch(loginFailedAction(errorMessage));
//       });
//   };
// }

// export function loginFailedAction(data) {
//   return {
//     type: LOGIN_FAILED_ACTION,
//     payload: data,
//   };
// }

// export function loginConfirmedAction(data) {
//   return {
//     type: LOGIN_CONFIRMED_ACTION,
//     payload: data,
//   };
// }

// export function confirmedSignupAction(payload) {
//   return {
//     type: SIGNUP_CONFIRMED_ACTION,
//     payload,
//   };
// }

// export function signupFailedAction(message) {
//   return {
//     type: SIGNUP_FAILED_ACTION,
//     payload: message,
//   };
// }

// export function loadingToggleAction(status) {
//   return {
//     type: LOADING_TOGGLE_ACTION,
//     payload: status,
//   };
// }

export function walletAddressAction(address) {
  return {
    type: WALLET_ADDRESS_ACTION,
    payload: address,
  };
}

export function userExistAction(address) {
  return {
    type: USER_EXIST_ACTION,
    payload: address,
  };
}

export function userAllDetailsAction(res) {
  
  return {
    type: USER_ALL_DETAILS_ACTION,
    payload: res,
  };
}
export function RefreshSatate(res) {
  return {
    type: USER_REFRESH_ACTION,
    payload: res,
  };
}

export function GetTokenDetails(res) {
  return {
    type: USER_TOKEN_ACTION,
    payload:res,
  };
}