import React, { useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import logo from "../../blacklogo.png";
import { LuHome } from "react-icons/lu";
import backTop from "../../images/browser/login_bg1.png";
import backbottom from "../../images/browser/flower.png";
import { FcGoogle } from "react-icons/fc";
import toast from "react-hot-toast";
import { changePassword, loginmail } from "../../helpers/api";
import OtpInput from "react-otp-input";
function ConfirmPassword() {
    const route = useLocation();
    const navigate = useNavigate();
    const { email, userId } = route.state || {}; // Access the email from the route state
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [otp, setOtp] = useState("");

    function handleLogin() {


        if (password.length < 8) {
            toast.error("Password must be at least 8 characters long");
            return;
        }
        if(!otp){
            toast.error("Enter Otp");
            return;
        }
        if(!email){
            toast.error("Email not find");
            return;
        }
        setLoading(true);
        const formData = { email: email, otp: otp, newPassword: password };

        changePassword(formData)
            .then((res) => {
                setLoading(false);
                if (res?.status) {
                    navigate("/login");
                    toast.success(res?.message);
                } else {
                    toast.error(res?.message);
                }
            })
            .catch((error) => {
                setLoading(false);
                toast.error("An error occurred");
            });
    }

    return (
        <>
            <div className="page_content gray_bg">
                <div className="container login_page_container">
                    <div className="login_header">
                        <div>
                            <Link to="/">
                                <img
                                    src={logo}
                                    alt="logo"
                                    className="mCS_img_loaded"
                                    height={50}
                                />
                            </Link>
                        </div>
                        <div className="d-flex gap-3">
                            <Link to="/">
                                <div className="short_icon_outline">
                                    <LuHome />
                                </div>
                            </Link>
                        </div>
                    </div>

                    <main className="page_wrapper">
                        <div className="side_img1">
                            <img src={backTop} alt="background top" />
                        </div>
                        <div className="login-card">
                            <h2 className="login_title mb-1">Forget Password</h2>
                            <form onSubmit={(e) => e.preventDefault()}>


                                <p className="text_gray">please check <span className="text-warning">{email}</span> Enter OTP</p>
                                <div>
                                    <div className="input-icon">
                                        <OtpInput
                                            value={otp}
                                            onChange={setOtp}
                                            numInputs={6}
                                            renderSeparator={<span>-</span>}
                                            renderInput={(props) => (
                                                <input
                                                    {...props}
                                                    className="otp-box"

                                                />
                                            )}
                                        />
                                    </div>


                                </div>

                                <div className="input-icon">
                                    <label className="text-dark">New Password*</label>
                                    <input
                                        type="password"
                                        id="password"
                                        name="password"
                                        className="form-control"
                                        placeholder="Your Password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                </div>

                                <div className="d-grid mb-1 mt-5 text-center">
                                    <button
                                        type="button"
                                        className="default-btn banner-btn"
                                        onClick={handleLogin}
                                        disabled={loading}
                                    >
                                        {loading ? (
                                            <div className="spinner-border text-light" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        ) : (
                                            "Submit Now"
                                        )}
                                    </button>
                                </div>


                            </form>
                        </div>
                        <div className="side_img2">
                            <img src={backbottom} alt="background bottom" />
                        </div>
                    </main>
                </div>
            </div>
        </>
    );
}

export default ConfirmPassword;
