import React from "react";
import img from "./Certificate (1).png";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";

const Certificate = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const userId = userDetails?.userId || "dmt333232"; // Example parent ID

  return (
    <div className="certificate-container">
      <div className="row justify-content-center">
        <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-12">
          <div className="card" style={{background:"#032500"}}>
            <div className="card-header border-0 pb-2 flex-wrap text-center">
              <h4 className="heading me-2 text-white">Certificate</h4>
            </div>
            <div className="card-body pt-2 position-relative">
              {/* Certificate image */}
              <img
                src={img}
                alt="Certificate"
                className="img-fluid certificate-image"
              />

              {/* Stylish Message */}
              <div className="tree-overlay">
                <div className="parent-id">
                  <h5>
                    Dear <span className="highlight">{userId}</span>,Now you are a{" "}
                    <span className="highlight"> member</span> of the
                    DMT Club.
                  </h5>
                  <Link to="/donationList">
                    <button className="btn xxs  btn-danger " style={{ marginBottom: "10px" }}>My Donation <MdKeyboardArrowRight size={20}/>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Custom CSS for overlay positioning */}
      <style jsx>{`
        .certificate-container {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
        }

        .certificate-image {
          display: block;
          margin: 0 auto;
          width: 80%;
          height: auto;
        }

        .tree-overlay {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
          width: 80%;
        }

        .parent-id h5 {
          font-size: 1.5rem;
          color: #000;
          margin-bottom: 20px;
          font-weight: 600;
        }

        .highlight {
          color: #d9534f; /* Stylish red color */
          font-weight: bold;
          font-size: 1.6rem;
        }

        @media (max-width: 768px) {
          .parent-id h5 {
            font-size: 1.1rem;
          }

          .highlight {
            font-size: 1.2rem;
          }

          .tree-overlay {
            bottom: 50px;
          }

          .certificate-image {
            width: 100%;
            height:auto;
          }
        }
      `}</style>
    </div>
  );
};

export default Certificate;
