import React, { useContext, useState, useEffect, useRef } from "react";

import { CgProfile } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import { GetTokenDetails } from "../../../store/actions/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import { getAllPackages } from "../../../helpers/api";

const Header = () => {
  const { userToken } = useSelector((action) => {
    return action.auth;
  });
  const walletDetails = JSON.parse(localStorage.getItem("walletDetails"));
  const walletAddress = walletDetails?.wallet;
  const [selectedOption, setSelectedOption] = useState("");
  const [allPackData, setAllPackData] = useState("");

  const dispatch = useDispatch();

  const [headerFix, setheaderFix] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setheaderFix(window.scrollY > 50);
    });
  }, []);
  const navigate = useNavigate();
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const email = userDetails?.email;
  const userId = userDetails?.userId;
  const popoverRef = useRef(null);
  const handleLogout = () => {
    localStorage.removeItem("walletDetails"); // Clear user details from local storage
    localStorage.removeItem("userDetails"); // Clear user details from local storage
    navigate("/login");
  };

  useEffect(() => {

    // Initialize the popover using Bootstrap's JavaScript API
    const popover = new window.bootstrap.Popover(popoverRef.current, {
      container: "body",
      html: true,
      placement: "bottom",
      content: `
        <div class="popover-body">
          <div class="dropdown-item">${email}</div>
          <a href="/logindashboard" class="dropdown-item">Switch to All User Board</a>
          <a href="" class="dropdown-item logout-btn">Logout</a>

      
        </div>
      `,
    });

    // Handle the click event for the logout button inside the popover
    document.addEventListener("click", (e) => {
      if (e.target.classList.contains("logout-btn")) {
        handleLogout();
      }
    });

    return () => {
      popover.dispose();
    };

  }, [email, walletAddress]);

  const handleOptionChange = (event) => {

    const { value, selectedOptions } = event.target;

    dispatch(GetTokenDetails({ value: value, name: selectedOptions[0].text }));
    setSelectedOption(value);
  };
  useEffect(() => {

    getAllPackages(userId).then((res) => [

      setAllPackData(res)

    ])
  }, [userId])

  useEffect(() => {
    if (!userToken.value) {
      // Automatically select the first package without triggering the handleOptionChange
      const firstPackage = allPackData[0];

      console.log(firstPackage,"firstPackage:::")
      setSelectedOption(firstPackage?.packageId);
      dispatch(GetTokenDetails({ value: firstPackage?.packageId, name: `Plan ${firstPackage?.packageId}` }));
    }
  }, [allPackData, selectedOption]);
  
  return (
    <div className={`header ${headerFix ? "is-fixed" : ""}`}>
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div
                className="dashboard_bar text-white"
                style={{ textTransform: "capitalize" }}
              >
                Congratulation {userId}  🎉!
              </div>
            </div>
            <div className="navbar-nav header-right">
              <div className="nav-item d-flex align-items-center">
                <div className="input-group">
                  <select
                    className="btn-xxs btn bg-warning text-white"
                    value={userToken?.value}
                    onChange={(e) => {
                      handleOptionChange(e);
                    }}
                  >
                    {allPackData &&
                      allPackData.map((res, i) => {
                        return (
                          <option key={i} value={res?.packageId}>
                            Plan  {res?.packageId==1?'520':res?.packageId==2?'280':res?.packageId==3?'1000':''} (USDT)
                          </option>
                        );
                      })}
                  </select>

                </div>
                <div className="input-group">
                  <div
                    ref={popoverRef}
                    className="nav-link"
                    data-bs-container="body"
                    data-bs-toggle="popover"
                    data-bs-placement="bottom"
                    data-bs-content="Bottom popover"
                  >
                    <CgProfile size={30} color="#fff" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
