import React from "react";
import { Link } from "react-router-dom";
import file11 from "../images/about.webp";
import Lottie from "lottie-react";

export const AbouUs = () => {
  return (
    <div>
      <section className="services-area pt-100 pb-70">
        <div className="container">
          <div className="section-title">
            <h2>About Us.</h2>
            <p>
              A Group with a Common Passion: We are the Dream Makers Team Club.
            </p>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12">
              <div className="about-img text-center">
              <img src={file11} alt="" />
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              <div className="about-text">
                <p>
                  In 2021, DMTCLUB was founded by a group of individuals who
                  decided to turn their shared interests into an official online
                  cryptocurrency club. After a few quick meetings, we were on
                  our way to creating something extraordinary. Our mission is to
                  make a global impact, providing support to people from all
                  nations and enabling financial freedom for everyone.
                </p>
                <p>
                  Our mission is to make a global impact, providing support to
                  people from all nations and enabling financial freedom for
                  everyone. By joining this platform, you will be part of a
                  community that values teamwork and development, allowing
                  everyone to grow financially and achieve happiness together.
                </p>
                <p>
                  Our founders come from diverse backgrounds and are successful
                  individuals from various fields and countries. Their
                  insightful decisions have greatly benefited our club members.
                  We believe that with your cooperation, we can develop even
                  more valuable projects within this club.
                </p>

                <p>
                  When you decide to join us, you will gain access to various
                  events and, more importantly, become part of a community of
                  people who are just as passionate as you are.
                </p>

              
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
